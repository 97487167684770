import Box, { BoxProps } from '@mui/material/Box';
// Technical Debt: It is not clear component
import { ReactComponent as LogoSvg } from 'src/assets/logoS360.svg';
import { ReactComponent as LogoMini } from 'src/assets/logoMini.svg';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'src/navigation';
import config from 'src/config';

type LogoProps = {
  // colored?: boolean;
  width?: number;
  isMinimised?: boolean;
} & BoxProps;

function Logo({ isMinimised, ...boxProps }: LogoProps) {
  const navigate = useNavigate();
  // Technical Debt: removed from SVG height={size}
  return (
    <Box {...boxProps} onClick={() => navigate(config.TOKEN ? PATHS.MY_PROFILE_PAGE : PATHS.LOGIN_PAGE)}>
      <Box sx={{ cursor: 'pointer' }}>{isMinimised ? <LogoMini data-testid="mini-logo" /> : <LogoSvg data-testid="standard-logo" />}</Box>
    </Box>
  );
}

export default Logo;
