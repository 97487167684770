import { useEffect, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GridRowParams, GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import { PATHS } from 'src/navigation';
import { setParams } from 'src/utils';
import { IncidentType, IncidentStatus } from 'src/types';
import { PermissionEnum } from 'src/shared/enums/permission.enum';
import { H1, P1R, Icon, Button, Toolbar, IncidentsTable, Loader, SomethingWentWrongState } from 'src/components';
import { incidentsColumnVisibilityModel } from 'src/components/IncidentsTable';
import emptyIncidents from 'src/assets/emptyIncidents.svg';
import config from 'src/config';

import { selectUser } from 'src/redux/user/selectors';
import { loadIncidents } from 'src/redux/incidents/actions';
import { selectIncidents, selectIncidentsLoading, selectIncidentsError } from 'src/redux/incidents/selectors';
import { deleteIncident, loadIncident, saveIncident } from 'src/redux/incident/actions';
import {
  selectIncident,
  selectIncidentSuccess,
  selectSaveIncidentError,
  selectSaveIncidentSuccess,
  selectDeletingIncidentSuccess,
} from 'src/redux/incident/selectors';
import { selectRosters } from 'src/redux/rosters/selectors';
import { loadRosters } from 'src/redux/rosters/actions';

import { useTranslation } from 'react-i18next';
import { DashboardWrapper } from '../wrappers';
import IncidentDialog from './IncidentDialog';
import DeleteIncidentPopup from './DeleteIncidentPopup';
import WarningPopup from './WarningPopup';
import IncidentCannotClosePopup from './IncidentCannotClosePopup';

export default function Incidents() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const incidents = useSelector(selectIncidents);
  const currentIncident = useSelector(selectIncident);
  const rosters = useSelector(selectRosters);
  const saveIncidentError = useSelector(selectSaveIncidentError);
  const isLoadIncidentSuccess = useSelector(selectIncidentSuccess);
  const isIncidentsLoading = useSelector(selectIncidentsLoading);
  const saveIncidentSuccess = useSelector(selectSaveIncidentSuccess);
  const deletingIncidentSuccess = useSelector(selectDeletingIncidentSuccess);
  const eventsLoadingError = useSelector(selectIncidentsError);
  const [editingIncident, setEditingIncident] = useState<undefined | IncidentType>(undefined);
  const [incidentToDelete, setIncidentToDelete] = useState<undefined | IncidentType>(undefined);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  // Technical Debt: It should be refactoring of IncidentCannotClosePopup
  const triedClose = currentIncident.status === IncidentStatus.CLOSED ? '1' : '';

  const onOpenClick = (event: MouseEvent<HTMLElement>, incident: IncidentType) => {
    event.stopPropagation();
    navigate(setParams(PATHS.INCIDENT_PAGE, { id: incident.id.toString() }));
  };

  const onEditClick = (event: MouseEvent<HTMLElement>, incident: IncidentType) => {
    setShowEditDialog(true);
    setEditingIncident(undefined);
    dispatch(loadIncident.reset());
    dispatch(loadIncident.init(incident));
    event.stopPropagation();
  };

  const onEditDialogClose = () => {
    setShowEditDialog(false);
    setEditingIncident(undefined);
  };

  const onDeleteClick = (event: MouseEvent<HTMLElement>, incident: IncidentType) => {
    event.stopPropagation();
    setIncidentToDelete(incident);
  };

  const handleDeleteIncident = () => {
    if (incidentToDelete?.id) {
      dispatch(deleteIncident.init(incidentToDelete));
    }
  };

  useEffect(() => {
    if (isLoadIncidentSuccess) {
      setEditingIncident(currentIncident);
      dispatch(loadIncident.reset());
    }
  }, [isLoadIncidentSuccess, currentIncident, dispatch]);

  useEffect(() => {
    if (incidentToDelete) {
      dispatch(loadRosters.init({ incidentId: incidentToDelete?.id }));
    }
  }, [incidentToDelete, dispatch]);

  useEffect(() => {
    if (user.uuid) {
      dispatch(loadIncidents.init());
    }
  }, [dispatch, user.uuid]);

  useEffect(() => {
    if (saveIncidentSuccess || deletingIncidentSuccess) {
      dispatch(loadIncidents.init());
    }
  }, [saveIncidentSuccess, deletingIncidentSuccess]);

  const getActions = (params: GridRowParams<any>): React.ReactElement<GridActionsCellItemProps>[] => {
    const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

    // VIEW_DETAILS_EVENTS && Open
    config.can(PermissionEnum.VIEW_DETAILS_EVENTS) &&
      actions.push(
        <GridActionsCellItem
          key="openCell"
          icon={<Icon name="open" />}
          label="Open"
          onClick={(event: MouseEvent<HTMLElement>) => onOpenClick(event, params.row)}
          showInMenu
        />,
      );

    // (EDIT_ANY_EVENT or EDIT_OWN_EVENT) && Edit
    config.can('edit_event', { user, incident: params.row }) &&
      actions.push(
        <GridActionsCellItem
          key="editCell"
          icon={<Icon name="edit" />}
          label="Edit"
          onClick={(event: MouseEvent<HTMLElement>) => onEditClick(event, params.row)}
          showInMenu
        />,
      );

    // DRAFT status and delete_event && delete
    params.row.status === IncidentStatus.DRAFT &&
      config.can('delete_event', { user, incident: params.row }) &&
      actions.push(
        <GridActionsCellItem
          disabled={!config.can('delete_event', { user, incident: params.row })}
          key="deleteCell"
          icon={<Icon name="delete" />}
          label="Delete"
          onClick={(event: MouseEvent<HTMLElement>) => onDeleteClick(event, params.row)}
          showInMenu
        />,
      );

    return actions;
  };

  const columnsVisibility = {
    ...incidentsColumnVisibilityModel,
    id: true,
    name: true,
    ownedBy: true,
    utilityContractor: true,
    ibewPay: true,
    fteCount: true,
    fleetCount: true,
    yardFK: true,
    expectedDate: true,
    status: true,
  };

  if (eventsLoadingError)
    return (
      <DashboardWrapper breadcrumbs={[[t('eventsPage.events')]]} navigationChildren={<NavigationWidget showToolbar={!!incidents?.length} />}>
        <SomethingWentWrongState />
      </DashboardWrapper>
    );

  return (
    <DashboardWrapper breadcrumbs={[[t('eventsPage.events')]]} navigationChildren={<NavigationWidget showToolbar={!!incidents?.length} />}>
      {isIncidentsLoading ? (
        <Loader />
      ) : incidents?.length ? (
        <Box component="main" sx={{ height: 'calc(100vh - 100px)', pt: 4 }}>
          <IncidentsTable
            authUser={user}
            incidents={incidents}
            columnVisibilityModel={columnsVisibility}
            getActions={getActions}
            setIncident={(sid: number): void =>
              navigate(setParams(PATHS.INCIDENT_PAGE, { id: sid.toString(), eventTab: 'myRosters', resourcesSubtab: 'linemen' }))
            }
            toolbarProps={{ options: 'mini' }}
          />
          <>
            {' '}
            {/* TODO: Need to add these to prevent error with IncidentCannotClosePopup, future fix */}
            {showEditDialog && editingIncident && <IncidentDialog isEdit onClose={onEditDialogClose} incident={editingIncident} />}
            {incidentToDelete && !rosters.length && (
              <DeleteIncidentPopup onClose={() => setIncidentToDelete(undefined)} onDelete={handleDeleteIncident} />
            )}
            {incidentToDelete && rosters.length && <WarningPopup onClose={() => setIncidentToDelete(undefined)} />}
            {saveIncidentError && <IncidentCannotClosePopup onClose={() => dispatch(saveIncident.reset())} errors={triedClose} />}
          </>
        </Box>
      ) : (
        <Box
          sx={{
            mt: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ ml: 1 }}>
            <img src={emptyIncidents} alt="Empty list" />
          </Box>
          <H1 sx={{ mb: 3 }}>{t('eventsPage.noEventsTitle')}</H1>
          <P1R>{t('eventsPage.noEventsDescription')}</P1R>
        </Box>
      )}
    </DashboardWrapper>
  );
}

type Props = {
  showToolbar: boolean;
};

const NavigationWidget: React.FC<Props> = ({ showToolbar }) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);

  return (
    <Box>
      <Box sx={{ gap: 3, display: 'flex' }}>
        {showToolbar && <Toolbar options="mini" />}
        {config.can(PermissionEnum.ADD_EVENT) && (
          <Button
            onClick={() => setShowDialog(true)}
            sx={{
              padding: '0 16px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
            }}
          >
            {t('eventsPage.createEvent')}
          </Button>
        )}
      </Box>

      {showDialog && <IncidentDialog onClose={() => setShowDialog(false)} />}
    </Box>
  );
};
