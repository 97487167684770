import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoadingContainer from 'src/containers/LoadingContainer';
import LoginContainer from 'src/containers/LoginContainer';
import {
  CreateAccountContainer,
  VerifyEmailContainer,
  EmailVerifiedContainer,
  WaitingContainer,
  ConfirmationContainer,
  NoResponseContainer,
  PhoneInUseContainer,
  ClearmeAuthContainer,
} from 'src/containers/CreateAccountContainer';
import DashboardContainer from 'src/containers/DashboardContainer';
import UsersContainer from 'src/containers/UsersContainer';
import UserProfileContainer from 'src/containers/UserProfileContainer';
import IncidentsContainer from 'src/containers/IncidentsContainer';
import IncidentContainer from 'src/containers/IncidentContainer';
import BroadcastsContainer from 'src/containers/BroadcastsContainer';
import RostersContainer from 'src/containers/RostersContainer';
import RosterContainer from 'src/containers/RosterContainer';
import FleetContainer from 'src/containers/FleetContainer';
import FleetDashboardContainer from 'src/containers/FleetDashboardContainer';
import FleetReportsContainer from 'src/containers/FleetReportsContainer';
import MyApprovalsContainer from 'src/containers/MyApprovalsContainer';
import MyUsersContainer from 'src/containers/MyUsersContainer';
import MyProfileContainer from 'src/containers/MyProfileContainer';
import SettingsContainer from 'src/containers/SettingsContainer';
import RbacMgmtContainer from 'src/containers/RbacMgmtContainer';
import NotFoundContainer from 'src/containers/NotFoundContainer';
import Error403Container from 'src/containers/Error403Container';
import Error404Container from 'src/containers/Error404Container';
import ErrorConnectionContainer from 'src/containers/ErrorConnectionContainer';
import { ForgotPasswordContainer, NewPassword, ResetPasswordViaEmail } from 'src/containers/ForgotPasswordContainer';
import CheckEmailContainer from 'src/containers/ForgotPasswordContainer/CheckEmail';
import ExpirationPageContainer from 'src/containers/ForgotPasswordContainer/ExpirationPage';
import RosterOverviewContainer from 'src/containers/RosterOverviewContainer';
import RosterSplitContainer from 'src/containers/RosterSplitContainer';
import TutorialsContainer from 'src/containers/TutorialsContainer';
import ClearmeSomethingWentWrongPage from 'src/containers/CreateAccountContainer/ClearmeSomethingWentWrongPage';
import MyAccountContainer from 'src/containers/MyAccountContainer';
import TablesContainer from 'src/containers/TablesContainer';
import { PATHS, PUBLIC_PATHS } from './constants';

const MainNavigation: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route path={PATHS.LOGIN_PAGE} element={<LoginContainer />} />

      <Route path={PATHS.CREATE_ACCOUNT_PAGE} element={<CreateAccountContainer />} />
      <Route path={PATHS.CREATE_ACCOUNT_VERIFY_EMAIL_PAGE} element={<VerifyEmailContainer />} />
      <Route path={PATHS.CREATE_ACCOUNT_EMAIL_VERIFIED_PAGE} element={<EmailVerifiedContainer />} />
      <Route path={PATHS.CREATE_ACCOUNT_WAITING_PAGE} element={<WaitingContainer />} />
      <Route path={PATHS.CREATE_ACCOUNT_CONFIRMATION_PAGE} element={<ConfirmationContainer />} />
      <Route path={PATHS.CLEARME_NO_RESPONSE} element={<NoResponseContainer />} />
      <Route path={PATHS.CREATE_ACCOUNT_PHONE_IN_USE} element={<PhoneInUseContainer />} />
      <Route path={PATHS.CLEARME_AUTH} element={<ClearmeAuthContainer />} />

      <Route path={PATHS.FORGOT_PASSWORD_PAGE} element={<ForgotPasswordContainer />} />
      <Route path={PATHS.RESET_PASSWORD_VIA_EMAIL} element={<ResetPasswordViaEmail />} />
      <Route path={PATHS.RESET_PASSWORD_NEW_PASSWORD} element={<NewPassword />} />
      <Route path={PATHS.RESET_PASSWORD_CHECK_EMAIL} element={<CheckEmailContainer />} />
      <Route path={PATHS.RESET_PASSWORD_EXPIRATION_PAGE} element={<ExpirationPageContainer />} />

      <Route path={PATHS.DASHBOARD_PAGE} element={<DashboardContainer />} />
      <Route path={PATHS.USERS_PAGE} element={<UsersContainer />} />
      <Route path={PATHS.USER_PROFILE_PAGE} element={<UserProfileContainer />} />
      <Route path={PATHS.INCIDENTS_PAGE} element={<IncidentsContainer />} />
      <Route path={PATHS.INCIDENT_PAGE} element={<IncidentContainer />} />
      <Route path={PATHS.BROADCASTS_PAGE} element={<BroadcastsContainer />} />
      <Route path={PATHS.ROSTERS_PAGE} element={<RostersContainer />} />
      <Route path={PATHS.ROSTER_PAGE} element={<RosterContainer />} />
      <Route path={PATHS.ROSTER_PAGE_SPLIT} element={<RosterSplitContainer />} />
      <Route path={PATHS.ROSTER_PAGE_OVERVIEW} element={<RosterOverviewContainer />} />
      <Route path={PATHS.FLEET_PAGE_DASHBOARD} element={<FleetDashboardContainer />} />
      <Route path={PATHS.FLEET_PAGE_REPORTS} element={<FleetReportsContainer />} />
      <Route path={PATHS.FLEET_PAGE_INVENTORY} element={<FleetContainer />} />
      <Route path={PATHS.MY_APPROVALS_PAGE} element={<MyApprovalsContainer />} />
      <Route path={PATHS.MY_USERS_PAGE} element={<MyUsersContainer />} />
      <Route path={PATHS.MY_PROFILE_PAGE} element={<MyProfileContainer />} />
      <Route path={PATHS.SETTINGS_PAGE} element={<SettingsContainer />} />
      <Route path={PATHS.SETTINGS_MY_ACCOUNT} element={<MyAccountContainer />} />
      <Route path={PATHS.SETTINGS_TABLES} element={<TablesContainer />} />
      <Route path={PATHS.SETTINGS_PAGE_RBAC} element={<RbacMgmtContainer />} />
      <Route path={PATHS.TUTORIALS} element={<TutorialsContainer />} />
      <Route path={PATHS.NOTFOUND_PAGE} element={<NotFoundContainer />} />
      <Route path={PATHS.ERROR_403_PAGE} element={<Error403Container />} />
      <Route path={PATHS.ERROR_404_PAGE} element={<Error404Container />} />
      <Route path={PATHS.ERROR_CONNECTION_PAGE} element={<ErrorConnectionContainer />} />
      <Route path={PATHS.CLEARME_SOMETHING_WENT_WRONG_PAGE} element={<ClearmeSomethingWentWrongPage />} />
      <Route path="*" element={<LoadingContainer message={t('common.notFoundPage')} />} />
    </Routes>
  );
};

export { PATHS, PUBLIC_PATHS };
export default MainNavigation;
