import styled from '@emotion/styled';
import { themeColors } from 'src/theme';
import { P3 } from '../Typography';

const StyledStatus = styled(P3)({
  padding: '0 10px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'lowercase',
  borderRadius: '4px',
});

const getClearStatus: Record<number, string> = {
  '-1': 'failed',
  0: 'not started',
  1: 'success',
  2: 'incomplete',
};

const getStatusColor: Record<number, string> = {
  '-1': themeColors.redLight,
  0: themeColors.lightYellow,
  1: themeColors.greenLight,
  2: themeColors.blueLight,
};

type ClearStatusProps = {
  clearStatus: number;
};

const ClearStatus = ({ clearStatus }: ClearStatusProps) => {
  return <StyledStatus sx={{ backgroundColor: getStatusColor[clearStatus] }}>{getClearStatus[clearStatus]}</StyledStatus>;
};

export default ClearStatus;
