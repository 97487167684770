import React, { ReactNode, useRef, useState } from 'react';
import { Box, ButtonGroup, ButtonProps, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, ThemeProvider, createTheme } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { theme, themeColors } from 'src/theme';
import StyledContainedLoadingButton from './styled';
import Icon from '../Icon';
import { P1R } from '../Typography';

type ListItem = {
  title: string | ReactNode;
  onClick: () => void;
  active: boolean;
};

type Props = {
  loading?: boolean;
  primary?: boolean;
  items?: ListItem[];
  buttonType?: 'group' | 'dropdown' | 'simple' | 'text';
  color?: string;
  label?: string;
  disabled?: boolean;
  sx?: any;
  children?: React.ReactNode;
  popperStyles?: { [key: string]: any };
} & ButtonProps;

const ContainedButton: React.FC<Props> = ({
  loading = false,
  label,
  primary = true,
  color,
  buttonType,
  items,
  disabled,
  sx = {},
  popperStyles,
  children,
  ...props
}): JSX.Element => {
  const buttonStyles = primary
    ? {
        padding: '0 35px',
        '&:hover': {
          backgroundColor: themeColors.yellowMain,
          color: themeColors.black,
        },
        '&:active': {
          background: lighten(themeColors.yellowMain, 0.2),
        },
        '&.Mui-disabled': {
          background: themeColors.grayLight,
          color: themeColors.grayDisabled,
        },
      }
    : {
        padding: '0 15px',
        borderColor: themeColors.grayMedium,
        '&:hover': {
          backgroundColor: themeColors.primary,
          color: 'white',
        },
        '&:active': {
          background: lighten(themeColors.primary, 0.2),
          color: 'white',
        },
        '&.Mui-disabled': {
          background: 'white',
          color: themeColors.grayDisabled,
        },
      };

  // For group button
  /* const [activeButtonId, setActiveButtonId] = useState(0); */

  const handleButtonClick = (index: number, onClick: () => void) => {
    /* setActiveButtonId(index); */
    onClick();
  };

  // For dropdown button
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (onClick: () => void) => {
    setOpen(false);
    onClick();
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // Group Button
  if (buttonType === 'group') {
    return (
      <ThemeProvider theme={createTheme(theme)}>
        <ButtonGroup sx={{ width: { xs: '100%', md: 'auto' } }}>
          {items?.map((item, index) => (
            <StyledContainedLoadingButton
              key={index}
              {...props}
              color={color}
              loading={loading}
              disabled={disabled}
              variant={item.active ? 'contained' : 'outlined'}
              onClick={() => handleButtonClick(index, item.onClick)}
              sx={{
                ...buttonStyles,
                ...sx,
                width: { xs: '100%', md: 'auto' },
                padding: '0, 14px',
                borderRadius: '5px',
                textTransform: 'none',
                borderColor: themeColors.grayMedium,
                '&:hover': {
                  borderColor: themeColors.grayMedium,
                },
                '&:active': {
                  backgroundColor: 'white',
                },
              }}
            >
              {item.title}
            </StyledContainedLoadingButton>
          ))}
        </ButtonGroup>
      </ThemeProvider>
    );
  }
  if (buttonType === 'dropdown') {
    // Dropdown Button
    return (
      <ThemeProvider theme={createTheme(theme)}>
        <ButtonGroup ref={anchorRef} sx={{ display: 'inline-block', m: '0 16px 0 16px' }}>
          <StyledContainedLoadingButton
            {...props}
            color={color}
            loading={loading}
            disabled={disabled}
            onClick={handleToggle}
            variant={primary ? 'contained' : 'outlined'}
            /* onClick={() => handleClickDropdown(items?.[selectedIndex]?.onClick)} */
            sx={{
              ...buttonStyles,
              ...sx,
              width: 'auto',
              padding: '0, 16px',
              textTransform: 'none',
            }}
          >
            {children || label || ''}
            {disabled ? <Icon name="expandGray" /> : <Icon name="expand" />}
          </StyledContainedLoadingButton>
          {/* {Array.isArray(items) && items?.length !== 0?
            <StyledContainedLoadingButton
              {...props}
              disabled={disabled}
              onClick={handleToggle}
              sx={{
                backgroundColor: 'black',
                '&:hover': {backgroundColor: themeColors.yellowMain},
                '&.Mui-disabled': {
                  backgroundColor: themeColors.grayLight
                }
              }}
            >
              <Icon name='expand' />
            </StyledContainedLoadingButton>
            :
            null
          } */}
        </ButtonGroup>

        <Popper sx={{ zIndex: 1, ...popperStyles }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem sx={{ padding: '0' }}>
                    {items?.map((item, index) => (
                      <MenuItem key={index} disabled={!item.active} onClick={() => handleMenuItemClick(item.onClick)} sx={{ padding: '0 14px' }}>
                        <Box
                          sx={{
                            height: '50px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <P1R>{item.title}</P1R>
                        </Box>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ThemeProvider>
    );
  }
  if (buttonType === 'simple') {
    return (
      <ThemeProvider theme={createTheme(theme)}>
        <Box sx={{ display: 'inline-block' }}>
          <StyledContainedLoadingButton
            {...props}
            color={color}
            loading={loading}
            disabled={disabled}
            variant={primary ? 'contained' : 'outlined'}
            sx={{
              ...buttonStyles,
              ...sx,
              textTransform: 'none',
              display: 'inline-block',
              p: '0 16px',
            }}
          >
            {children || label || ''}
          </StyledContainedLoadingButton>
        </Box>
      </ThemeProvider>
    );
  }
  if (buttonType === 'text') {
    return (
      <ThemeProvider theme={createTheme(theme)}>
        <Box sx={{ display: 'inline-block' }}>
          <StyledContainedLoadingButton
            {...props}
            color={color}
            loading={loading}
            disabled={disabled}
            sx={{
              ...sx,
            }}
          >
            {children || label || ''}
          </StyledContainedLoadingButton>
        </Box>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={createTheme(theme)}>
      <Box>
        <StyledContainedLoadingButton
          {...props}
          color={color}
          loading={loading}
          disabled={disabled}
          variant={primary ? 'contained' : 'outlined'}
          sx={{ ...buttonStyles, ...sx }}
        >
          {children || label || ''}
        </StyledContainedLoadingButton>
      </Box>
    </ThemeProvider>
  );
};

export default ContainedButton;
