import { Box, Grid } from '@mui/material';
import { Button, Dialog, H1, P1R } from 'src/components';
import { useTranslation } from 'react-i18next';
import { themeColors } from 'src/theme';
import trashIcon from '../../assets/trashRedIcon.svg';

type Props = {
  isLoading: boolean;
  open: boolean;
  title: string;
  subTitle: string;
  onDelete: () => void;
  onClose: () => void;
};

// TODO: move to /components and reuse for all delete confirmations
const DeleteAvatarConfirmationPopup = ({ title, subTitle, open, isLoading, onDelete, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          mb: 5,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ mt: 5 }}>
          <img src={trashIcon} alt="delete" />
        </Box>
        <H1 align="center" sx={{ mt: 4, mb: 3 }}>
          {title}
        </H1>
        <P1R>{subTitle}</P1R>
      </Box>

      <Grid container columnSpacing={2} sx={{ mb: 4 }}>
        <Grid item xs={6}>
          <Button
            primary={false}
            onClick={onClose}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('profilePage.cancel')}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            onClick={onDelete}
            loading={isLoading}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
              backgroundColor: themeColors.red,
            }}
          >
            {t('profilePage.yesDelete')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteAvatarConfirmationPopup;
