import { styled } from '@mui/material/styles';
import { P3 } from 'src/components';
import { UnionInfoStatus } from 'src/shared/enums/unionInfoStatus.enum';
import { themeColors } from 'src/theme';

const StyledStatus = styled(P3)({
  padding: '0 10px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'lowercase',
  borderRadius: '4px',
});

type Props = {
  verificationStatus: UnionInfoStatus;
};

function VerificationStatus({ verificationStatus: status }: Props) {
  const getStatus = (status: UnionInfoStatus) => {
    const statuses = {
      0: 'pending',
      1: 'verified',
      '-1': 'rejected',
    };
    return statuses[status];
  };

  const getStatusColor = (status: UnionInfoStatus) => {
    const statuses = {
      0: themeColors.lightYellow,
      1: themeColors.greenLight,
      '-1': themeColors.redLight,
    };
    return statuses[status];
  };

  return (
    <StyledStatus
      sx={{
        backgroundColor: () => getStatusColor(status),
      }}
    >
      {getStatus(status)}
    </StyledStatus>
  );
}

export default VerificationStatus;
