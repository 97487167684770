const PUBLIC_PATHS = {
  LOGIN_PAGE: '/login',
  CREATE_ACCOUNT_PAGE: '/create-account',
  CREATE_ACCOUNT_VERIFY_EMAIL_PAGE: '/create-account/verify-email',
  CREATE_ACCOUNT_EMAIL_VERIFIED_PAGE: '/create-account/email-verified',
  CREATE_ACCOUNT_DOCUMENTS_FIELD_WORKER_PAGE: '/create-account/documents-field-worker',
  CREATE_ACCOUNT_DOCUMENTS_OFFICE_WORKER_PAGE: '/create-account/documents-office-worker',
  CREATE_ACCOUNT_WAITING_PAGE: '/create-account/waiting',
  CREATE_ACCOUNT_CONFIRMATION_PAGE: '/create-account/confirmation',
  CLEARME_NO_RESPONSE: '/create-account/no-reponse',
  FORGOT_PASSWORD_PAGE: '/forgot-password',
  RESET_PASSWORD_VIA_EMAIL: '/forgot-password/via-email',
  RESET_PASSWORD_NEW_PASSWORD: '/reset/:token',
  RESET_PASSWORD_CHECK_EMAIL: '/forgot-password/check-email',
  RESET_PASSWORD_EXPIRATION_PAGE: '/forgot-password/expiration-page',
  CLEARME_SOMETHING_WENT_WRONG_PAGE: '/create-account/clearme-something-went-wrong',
  CREATE_ACCOUNT_PHONE_IN_USE: '/create-account/phone-in-use',
  CLEARME_AUTH: '/create-account/clearme-auth',

  NOTFOUND_PAGE: '/not-found',
  ERROR_403_PAGE: '/403',
  ERROR_404_PAGE: '/404',
  ERROR_CONNECTION_PAGE: '/connection',
};

const PATHS = {
  LOADING_PAGE: '/',
  DASHBOARD_PAGE: '/dashboard',
  USERS_PAGE: '/users',
  USER_PROFILE_PAGE: '/user/:uuid/profile',
  INCIDENTS_PAGE: '/events',
  INCIDENT_PAGE: '/events/:id/:eventTab/:resourcesSubtab',
  BROADCASTS_PAGE: '/broadcasts',
  ROSTERS_PAGE: '/rosters',
  ROSTER_PAGE: '/rosters/:id',
  ROSTER_PAGE_SPLIT: '/rosters/:id/split',
  ROSTER_PAGE_OVERVIEW: '/rosters/overview/:id/:version',
  ROSTERS_MY_PAGE: '/rosters/:id/my',
  FLEET_PAGE: '/fleet',
  FLEET_PAGE_DASHBOARD: '/fleet/dashboard',
  FLEET_PAGE_REPORTS: '/fleet/reports',
  FLEET_PAGE_INVENTORY: '/fleet/inventory',
  MY_APPROVALS_PAGE: '/my/approvals',
  MY_USERS_PAGE: '/my/managed_users',
  MY_PROFILE_PAGE: '/my/profile',
  TUTORIALS: '/tutorials',
  SETTINGS_PAGE: '/settings/*',
  SETTINGS_MY_ACCOUNT: '/settings/myAccount',
  SETTINGS_TABLES: '/settings/tables',
  SETTINGS_PAGE_RBAC: '/settings/rbac',

  ...PUBLIC_PATHS,
};

export { PATHS, PUBLIC_PATHS };
