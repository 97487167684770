import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';

import { UnionInfoStatus } from 'src/shared/enums/unionInfoStatus.enum';
import { SagaAction } from '../../types';
import { verifyUnionInfo } from '../actions';

type UserPayloadType = {
  uuid: string;
  verifyInfo: { unionInfoStatus: UnionInfoStatus; unionRejectedComment?: string };
};

function* verifyUnionInfoSaga({ payload: { uuid, verifyInfo } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(verifyUnionInfo.pending());
    yield call(API.verifyUnionInfo, { uuid, verifyInfo });
    yield put(verifyUnionInfo.success());
  } catch (e) {
    yield put(verifyUnionInfo.failure());
  }
}

export default verifyUnionInfoSaga;
