import React, { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Menu, MenuItem, Typography, Card, CardHeader, CardContent, CardActions, IconButton, Popper } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { PATHS } from 'src/navigation';
import { RosterType, UserType, RosterStatus as RosterStatusEnum, RosterApprovalStatus } from 'src/types';
import { Icon, P1M, P1R, P2R } from 'src/components';
import { setParams, formatIncidentId } from 'src/utils';
import config from 'src/config';
import { themeColors } from 'src/theme';
import { loadLinemen } from 'src/redux/users/actions';
import { saveRoster } from 'src/redux/roster/actions';
import TransferPopup from './TransferPopup';
import EditRosterDialog from '../RosterContainer/Dialog';
import RosterStatus from '../../components/RostersTable/RosterStatus';

type Props = {
  user: UserType;
  roster: RosterType;
  onRosterDelete?: () => void;
};

const rosterStatusesOptions: { [key: number]: number[] } = {
  [RosterStatusEnum.DRAFT]: [RosterStatusEnum.CLOSED],
  [RosterStatusEnum.PENDING_APPROVAL]: [RosterStatusEnum.MOBILIZED],
  [RosterStatusEnum.MOBILIZED]: [RosterStatusEnum.ACTIVATED],
  [RosterStatusEnum.ACTIVATED]: [RosterStatusEnum.DEMOBILIZED],
  [RosterStatusEnum.DEMOBILIZED]: [RosterStatusEnum.ACTIVATED, RosterStatusEnum.CLOSED],
};

const CardRoster: React.FC<Props> = ({ roster, onRosterDelete, ...props }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [statusesOptions, setStatusesOptions] = useState<number[]>([]);
  const [isTransfering, setIsTransfering] = useState<boolean>(false);
  const [isTransferingEdit, setIsTransferingEdit] = useState<boolean>(false);
  const [incidentIdToTransfer, setIncidentIdToTransfer] = useState<number>(0);
  const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(null);
  const statusPopoverOpen = Boolean(anchorEl2);
  const isReapprovalRequired = roster.approvalStatus === RosterApprovalStatus.PENDING && roster.status !== RosterStatusEnum.PENDING_APPROVAL;
  const isRejected = roster.approvalStatus === RosterApprovalStatus.REJECTED;

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, rosterStatus: number) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setStatusesOptions(rosterStatusesOptions[rosterStatus] || []);
  };

  const handleStatusChange = (event: MouseEvent<HTMLElement>, newStatus: number) => {
    event.stopPropagation();
    dispatch(saveRoster.init({ id: roster.id, status: newStatus }));
    if (newStatus === 5) {
      // Closed
      dispatch(loadLinemen.init({ incidentId: roster.incidentId }));
    }
    setAnchorEl(null);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    onRosterDelete?.();
  };

  const handleTransferToClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsTransfering(true);
  };

  const handleTransferCancel = () => {
    setIsTransfering(false);
    setIsTransferingEdit(false);
    setIncidentIdToTransfer(0);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl2(null);
  };

  return (
    <>
      <Card
        key={roster.id}
        variant="outlined"
        onClick={() => navigate(setParams(PATHS.ROSTER_PAGE, { id: `${roster.id}` }))}
        sx={{
          maxWidth: 345,
          minHeight: '146px',
          '&:hover': { borderColor: themeColors.yellowMain },
        }}
      >
        <CardHeader
          action={
            roster.status === RosterStatusEnum.CLOSED ? null : (
              <IconButton aria-label="settings" onClick={(event) => handleMenuOpen(event, roster.status)}>
                <MoreHorizIcon />
              </IconButton>
            )
          }
          subheader={
            <P2R>
              {t('eventPage.id')}
              {formatIncidentId(roster.id)}
            </P2R>
          }
          sx={{ padding: '16px 16px 0 16px' }}
        />
        <CardContent sx={{ padding: '16px 16px 0 16px' }}>
          <P1M>{roster.name}</P1M>
        </CardContent>
        <CardActions
          disableSpacing
          sx={{
            padding: '24px 16px 16px 16px ',
            justifyContent: 'space-between',
          }}
        >
          {/* // TODO: hack to compensate 4px in Icon component */}
          <Box sx={{ my: '4px' }}>
            <RosterStatus status={roster.status} />
          </Box>
          <Box
            aria-owns={statusPopoverOpen ? 'status-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{ cursor: 'pointer' }}
          >
            {isReapprovalRequired ? <Icon name="statusReapproval" /> : null}
            {isRejected ? (
              <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
                <P2R sx={{ color: themeColors.red }}>{t('rosterPage.rejected')}</P2R>
                <Icon name="statusRejected" />
              </Box>
            ) : null}
          </Box>
        </CardActions>

        {/* // TODO: fix popover to inherit theme styling */}
        <Popper open={statusPopoverOpen} anchorEl={anchorEl2} placement="bottom" disablePortal>
          <Box
            sx={{
              p: 3,
              border: `1px solid ${themeColors.grayMedium}`,
              backgroundColor: themeColors.backgroundColor,
              fontFamily: 'Poppins, Roboto, sans-serif',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {isReapprovalRequired ? (
              <>
                <Typography
                  variant="h3"
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: '1rem',
                    lineHeight: 1.3,
                    mb: 2,
                  }}
                >
                  {t('rosterPage.rosterReApproval')}
                </Typography>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: 1.3,
                  }}
                >
                  {t('eventPage.someChangesInRoster')}
                  <br />
                  {t('eventPage.doNotForget')}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant="h3"
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: '1rem',
                    lineHeight: 1.3,
                    mb: 2,
                  }}
                >
                  {t('rosterPage.rosterRejected')}
                </Typography>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: 1.3,
                  }}
                >
                  {t('eventPage.someChangesInRoster')}
                  <br />
                  {t('eventPage.businessMangerDidNotApprove')}
                </Typography>
              </>
            )}
          </Box>
        </Popper>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {roster.status === RosterStatusEnum.DRAFT && config.can('delete_roster') ? (
            <MenuItem
              onClick={handleDeleteClick}
              sx={{
                margin: '-5px',
                width: '184px',
                height: '50px',
                gap: 2,
                display: 'flex',
              }}
            >
              <Icon name="delete" />
              <P1R>Delete</P1R>
            </MenuItem>
          ) : null}

          {config.can('transfer_roster') && Object.keys(rosterStatusesOptions).includes(roster.status.toString()) ? (
            <MenuItem
              onClick={handleTransferToClick}
              sx={{
                margin: '-5px',
                height: '50px',
                gap: 2,
                display: 'flex',
              }}
            >
              <Icon name="duplicate" />
              <P1R>Transfer to...</P1R>
            </MenuItem>
          ) : null}

          {[1, 5].includes(roster.status) ? null : (
            <Box
              sx={{
                paddingLeft: 2,
                height: '50px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <P1R sx={{ color: themeColors.grayDark }}>{t('eventPage.changeStatusTo')}</P1R>
            </Box>
          )}
          {statusesOptions.map((status, index) => (
            <MenuItem key={index} sx={{ height: '50px' }} onClick={(event) => handleStatusChange(event, status)}>
              <RosterStatus status={status} />
            </MenuItem>
          ))}
        </Menu>
      </Card>
      {isTransfering && (
        <TransferPopup
          id={roster.id}
          name={roster.name}
          onClose={handleTransferCancel}
          onSelectIncident={setIncidentIdToTransfer}
          onNextClick={() => setIsTransferingEdit(true)}
        />
      )}
      {isTransferingEdit && (
        <EditRosterDialog
          template="edit-roster"
          onClose={handleTransferCancel}
          incidentId={roster.incidentId as number}
          roster={roster}
          incidentIdToTransfer={incidentIdToTransfer}
        />
      )}
    </>
  );
};

export default CardRoster;
