import { StatusActionState, ReduxAction } from '../types';

type ErrorActionPayload = {
  errorPayload?: string;
  data?: any;
};

export const ERROR_PAYLOAD = 'ERROR_PAYLOAD';

const errorsReducer = (state: StatusActionState = {}, action: ReduxAction<ErrorActionPayload>): StatusActionState => {
  const { type, payload = {} } = action;
  const matches = /(.*)_(PENDING|FAILURE|RESET|SUCCESS)/.exec(type);

  // not a *_FAILURE / *_FAILURE_SILENT actions, so we ignore them
  if (!matches) {
    return state;
  }

  const { data } = payload;
  const [, requestName, requestState] = matches;

  const newState = {
    ...state,
    [requestName]: requestState === 'FAILURE',
  };

  delete newState[`${requestName}_${ERROR_PAYLOAD}`];

  if (data?.error?.messages) {
    newState[`${requestName}_${ERROR_PAYLOAD}`] = data.error.messages.common ? data.error.messages.common : data.error.messages;
  }

  return newState;
};

export default errorsReducer;
