import { useEffect, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@mui/material';
import { GridRowParams, GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid';
import { UserType, VehicleType } from 'src/types';
import { RosterStatus, RosterType } from 'src/types/roster';
import { Icon, P1R, RosterTable, EmptyState } from 'src/components';
import { TableAdvancedMetaColumn } from 'src/components/Table/TableAdvanced';
import { rosterColumnVisibilityModel } from 'src/components/RosterTable';
import { loadRoster, rosterDetachLineman, rosterLinemanSetOptions, rosterAssignments } from 'src/redux/roster/actions';
import { selectRosterLinemanSetOptionsSuccess, selectRosterAssignmentsSuccess } from 'src/redux/roster/selectors';
import { selectUser } from 'src/redux/user/selectors';
import { PATHS } from 'src/navigation';
import { themeColors } from 'src/theme';
import config from 'src/config';

import DetachPopup from '../DetachPopup';

const boxStyle = {
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
};

type Props = {
  roster: RosterType;
  ibewPay: string;
  rosterStatus: number;
  linemen?: Partial<UserType>[] | null | undefined;
  fleet?: VehicleType[];
  utilityId?: number;
  rowSelectionModel: any;
  readOnly: boolean;
  onRowSelectionModelChange: (selected: any) => void;
};

function PeopleTab({
  roster,
  ibewPay,
  rosterStatus,
  linemen,
  fleet,
  utilityId,
  rowSelectionModel,
  readOnly,
  onRowSelectionModelChange,
  ...props
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const rosterLinemanSetOptionsSuccess = useSelector(selectRosterLinemanSetOptionsSuccess);
  const rosterAssignmentsSuccess = useSelector(selectRosterAssignmentsSuccess);
  const [editingLineman, setEditingLineman] = useState<any | undefined>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const isEditable = !readOnly && config.can('edit_roster', { user, roster });

  useEffect(() => {
    if (rosterLinemanSetOptionsSuccess) {
      dispatch(loadRoster.init({ id: roster.id }));
      dispatch(rosterLinemanSetOptions.reset());
    }
  }, [rosterLinemanSetOptionsSuccess]);

  useEffect(() => {
    if (rosterAssignmentsSuccess) {
      dispatch(loadRoster.init({ id: roster.id }));
      dispatch(rosterAssignments.reset());
    }
  }, [rosterAssignmentsSuccess]);

  const detachLineman = (event: MouseEvent<HTMLElement>, lineman: any) => {
    event.stopPropagation();
    setEditingLineman(lineman);
  };

  if (linemen?.length) {
    const handleDetachLineman = (id: number | undefined, uuid: string | undefined) => {
      dispatch(rosterDetachLineman.init({ id, uuids: [uuid] }));
    };
    const getActions = (params: GridRowParams<any>): React.ReactElement<GridActionsCellItemProps>[] => [
      <GridActionsCellItem
        key="deleteCell"
        icon={<Icon name="delete" />}
        label="Delete"
        disabled={editMode || readOnly || rosterStatus === RosterStatus.PENDING_APPROVAL || roster.ownerUuid === params.row.id}
        onClick={(event: MouseEvent<HTMLElement>) => detachLineman(event, params.row)}
      />,
    ];

    const metaColumns: TableAdvancedMetaColumn[] = [
      {
        index: 0,
        column: {
          field: 'alert',
          width: 90,
          headerName: t('rosterPeoplePage.alert'),
          renderCell: (params: any) => (params.row.alert ? <Icon name="statusWarning" /> : ''),
        },
      },
    ];

    const metaRows = linemen.map((item) => ({
      alert: item?.assignments?.[0]?.approveRequired,
    }));

    const handleUpdatePeopleOfRoster = (data: any) => {
      dispatch(
        rosterAssignments.init({
          rosterId: roster.id,
          assignments: Object.keys(data).map((uuid) => ({
            uuid,
            crew: data[uuid]?.crewId,
            utilityFteClassification: data[uuid]?.utilityFteClassification,
            timesheetFteClassification: data[uuid]?.timesheetFteClassification,
            unionClassification: data[uuid]?.unionClassification,
            vehicleId: data[uuid]?.assignedVehicle?.id,
            lodging: data[uuid]?.lodging,
            isLead: data[uuid]?.crewLead,
          })),
        }),
      );
    };

    const columnsVisibility = {
      ...rosterColumnVisibilityModel,
      fullName: true,
      inviteStatus: true,
      utilityFteClassification: true,
      assignedVehicle: true,
      crewId: true,
      crewLead: true,
      companyTag: true,
      gender: true,
      lodging: true,
    };

    return (
      <>
        {(linemen.length ?? 0) ? (
          <Box sx={{ gap: '24px', display: 'flex', mb: '25px' }}>
            <Box style={boxStyle}>
              <Icon name="foreman" />
              <P1R>{t('fteStatuses.crewLead')}</P1R>
            </Box>
          </Box>
        ) : null}
        <RosterTable
          ibewPay={ibewPay}
          rosterId={roster.id || 0}
          rosterStatus={rosterStatus}
          linemen={linemen}
          fleet={fleet}
          utilityId={utilityId}
          isEditable={isEditable}
          metaColumns={metaColumns}
          metaRows={metaRows}
          columnVisibilityModel={columnsVisibility}
          getActions={isEditable ? getActions : undefined}
          onRowSelectionModelChange={(selected: any) => {
            onRowSelectionModelChange(selected);
          }}
          rowSelectionModel={rowSelectionModel}
          processRowsUpdate={handleUpdatePeopleOfRoster}
          onBulkEditStart={isEditable ? () => setEditMode(true) : undefined}
          onBulkEditStop={isEditable ? () => setEditMode(false) : undefined}
          checkboxSelection={!readOnly}
        />
        <DetachPopup
          name={`${editingLineman?.fullName}`}
          open={!!editingLineman}
          onDetach={() => handleDetachLineman(roster.id, editingLineman?.id)}
          onClose={() => setEditingLineman(undefined)}
        />
      </>
    );
  }
  return (
    <EmptyState
      type="people"
      title={t('rosterPage.fteEmptyTitle')}
      subTitle={
        <P1R>
          {t('rosterPage.addPeople')}
          <br /> {t('rosterPage.or')}{' '}
          <Link
            component={RouterLink}
            to={`${PATHS.INCIDENTS_PAGE}/${roster.incident?.id}/available-linemen`}
            sx={{ textDecoration: 'none', color: themeColors.blue }}
          >
            {' Resourses '}
          </Link>
        </P1R>
      }
    />
  );
}

export default PeopleTab;
