import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, styled } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, H1, Icon, Input, P1M, P1R, P3 } from 'src/components';
import { PATHS } from 'src/navigation';
import { rosterSetStatus } from 'src/redux/roster/actions';
import { themeColors } from 'src/theme';
import { setParams } from 'src/utils';

type DetachPopupProps = {
  onClose: () => void;
  rosterName: string;
  rosterId?: number;
  incidentId?: number;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function DetachPopup({ onClose, rosterName, rosterId, incidentId }: DetachPopupProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isLengthError, setIsLengthError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value;

    if (newVal.length <= 512) {
      setIsLengthError(false);
      setInputValue(newVal);
    } else {
      setIsLengthError(true);
      setInputValue(newVal);
    }
  };

  const handleClose = (message: string) => {
    dispatch(rosterSetStatus.init({ id: rosterId, status: 0, message }));
    navigate(setParams(PATHS.INCIDENT_PAGE, { id: incidentId?.toString() || '' }));
    onClose();
  };

  return (
    <Box>
      <BootstrapDialog open onClose={onClose} aria-labelledby="customized-dialog-title">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <H1 align="center" sx={{ mt: 5, mb: 3 }}>
            {t('rosterPage.rejectRoster')}
          </H1>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <P1R>
              {t('rosterPage.aboutToReject')} <P1M>{rosterName}</P1M>
            </P1R>
          </Box>
        </BootstrapDialogTitle>
        <DialogContent>
          <Input
            isOptional
            label="Comment"
            placeholder="Type text here..."
            multiline
            rows={6}
            value={inputValue}
            onChange={handleInputChange}
            sx={{ '& .MuiInputBase-root': { height: '100%' } }}
          />

          {isLengthError ? (
            <Box sx={{ mt: -2 }}>
              <P3 sx={{ color: themeColors.red }}>{t('rosterPage.characterLimit')}</P3>
            </Box>
          ) : null}

          <Grid container columnSpacing={2} sx={{ mb: 5 }}>
            <Grid item xs={6}>
              <Button primary={false} onClick={onClose} sx={{ width: '100%', textTransform: 'capitalize' }}>
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={isLengthError}
                onClick={() => handleClose(inputValue)}
                sx={{
                  backgroundColor: themeColors.red,
                  width: '100%',
                  textTransform: 'capitalize',
                }}
              >
                {t('rosterPage.yesReject')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}

export default DetachPopup;
