import { useState, useRef, ChangeEvent, useEffect } from 'react';
import { Box, Slider, useMediaQuery, useTheme } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { Dialog, H1, Button, Avatar, P1R, P3 } from 'src/components';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { UserType } from 'src/types';
import { themeColors } from 'src/theme';
import { useDispatch, useSelector } from 'react-redux';
import { uploadUserFile, deleteUserFile, loadUser } from 'src/redux/user/actions';
import { selectSuccessUploadingUserFile, selectUploadingUserFile, selectSuccessDeleteUserFile, selectDeleteUserFile } from 'src/redux/user/selectors';
import DeleteAvatarConfirmationPopup from './DeleteAvatarConfirmationPopup';

type Props = {
  user: UserType;
  onClose: () => void;
};

const MAX_IMG_SIZE = 4 * 1024 * 1024;

const UploadAvatarModal = ({ user, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isEditMode, setIsEditMode] = useState(false);
  const [slideValue, setSlideValue] = useState(10);
  const [cropSrc, setCropSrc] = useState<string | null>(null);
  const [preview, setPreview] = useState('');
  const [showSizeError, setShowSizeError] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const cropRef = useRef(null);
  const inputRef = useRef(null);
  const displayName = `${user.firstName ?? 'New'} ${user.lastName ?? 'User'}`;
  const uploadProfilePictureSuccess = useSelector(selectSuccessUploadingUserFile);
  const uploadingProfilePicture = useSelector(selectUploadingUserFile);
  const deleteProfilePictureSuccess = useSelector(selectSuccessDeleteUserFile);
  const deletingProfilePicture = useSelector(selectDeleteUserFile);

  // @ts-ignore
  const handleInputClick = (e) => {
    e.preventDefault();
    // @ts-ignore
    inputRef.current.click();
  };

  const handleImgChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    setShowSizeError(false);

    if (file && file.size > MAX_IMG_SIZE) {
      setShowSizeError(true);
      e.target.value = '';
    } else if (file) {
      setCropSrc(URL.createObjectURL(file));
      setIsEditMode(true);
    }
  };

  const handleSave = async () => {
    if (cropRef) {
      // @ts-ignore
      const dataUrl = cropRef?.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      dispatch(uploadUserFile.init({ userUuid: user.uuid, file: blob, type: 'profile_picture' }));
    }
  };

  const handleDeleteImg = () => {
    dispatch(deleteUserFile.init({ id: user.attachments?.profile_picture?.id }));
  };

  useEffect(() => {
    if (uploadProfilePictureSuccess) {
      dispatch(uploadUserFile.reset());
      setIsEditMode(false);
      onClose();
    }
    if (deleteProfilePictureSuccess) {
      dispatch(deleteUserFile.reset());
      dispatch(loadUser.init());
      setIsEditMode(false);
      setShowDeletePopup(false);
      onClose();
    }
  }, [uploadProfilePictureSuccess, deleteProfilePictureSuccess]);

  useEffect(() => {
    if (user.attachments?.profile_picture) {
      setPreview(user.attachments?.profile_picture?.location);
    }
  }, [user.attachments]);

  return (
    <Dialog open onClose={onClose} fullScreen={fullScreen}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', maxWidth: 322, py: 2, mx: 'auto' }}>
        <H1 sx={{ my: 2, textAlign: 'center', textTransform: 'capitalize' }}>
          {preview ? 'Edit' : 'Add'} {t('profilePage.profilePicture')}
        </H1>
        {isEditMode ? (
          <>
            <Box sx={{ width: '100%', height: '42px', backgroundColor: themeColors.black, mt: 2 }} />
            <AvatarEditor
              ref={cropRef}
              image={cropSrc || ''}
              style={{ maxWidth: '322px', maxHeight: '322px', width: '100%', height: '100%' }}
              border={50}
              borderRadius={150}
              color={[0, 0, 0, 0.4]}
              scale={slideValue / 10}
              rotate={0}
            />
            <Box
              sx={{
                width: '100%',
                height: '42px',
                backgroundColor: themeColors.black,
                color: themeColors.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <P1R>{t('profilePage.dragPhoto')}</P1R>
            </Box>
            <Box sx={{ width: '100%', my: 2 }}>
              <P1R>{t('profilePage.zoom')}</P1R>
              <Slider
                min={10}
                max={50}
                sx={{
                  margin: '-10px auto 0',
                  width: '100%',
                  color: themeColors.yellowMain,
                }}
                size="small"
                defaultValue={slideValue}
                value={slideValue}
                // @ts-ignore
                onChange={(e) => setSlideValue(e?.target?.value)}
              />
            </Box>
          </>
        ) : (
          <>
            <Avatar name={displayName} alt="avatar" src={preview} fontSize="42px" sx={{ width: 250, height: 250, mb: 3, mt: 6 }} />
            {showSizeError ? (
              <P3 sx={{ color: themeColors.error }}>{t('profilePage.imgSizeError')}</P3>
            ) : (
              <P3 sx={{ color: themeColors.grayPlaceholders }}>{t('profilePage.imgDescription')}</P3>
            )}
          </>
        )}

        {preview && !isEditMode ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 3 }}>
            <Button
              buttonType="text"
              component="label"
              role={undefined}
              onClick={handleInputClick}
              sx={{ color: themeColors.blue, textTransform: 'capitalize' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
                <AddAPhotoOutlinedIcon fontSize="small" />
                <P1R>{t('profilePage.changePhoto')}</P1R>
              </Box>
            </Button>
            <input hidden type="file" accept=".jpg, .png, .jpeg" ref={inputRef} onChange={handleImgChange} />
            <Button buttonType="text" onClick={() => setShowDeletePopup(true)} sx={{ color: themeColors.blue, textTransform: 'capitalize' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
                <DeleteOutlinedIcon fontSize="small" />
                <P1R>{t('profilePage.deletePhoto')}</P1R>
              </Box>
            </Button>
          </Box>
        ) : null}

        {!preview && !isEditMode ? (
          <>
            <Button component="label" role={undefined} onClick={handleInputClick} sx={{ width: '320px', textTransform: 'capitalize', my: 3 }}>
              <FileUploadIcon fontSize="small" sx={{ mr: 1 }} />
              {t('profilePage.uploadPhoto')}
            </Button>
            <input hidden type="file" accept=".jpg, .png, .jpeg" ref={inputRef} onChange={handleImgChange} />
          </>
        ) : null}

        {isEditMode ? (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Button
              onClick={handleSave}
              loading={uploadingProfilePicture || deletingProfilePicture}
              sx={{ textTransform: 'capitalize', width: '322px' }}
            >
              {t('profilePage.save')}
            </Button>
            <Button primary={false} onClick={onClose} sx={{ textTransform: 'capitalize', width: '322px' }}>
              {t('profilePage.cancel')}
            </Button>
          </Box>
        ) : null}
      </Box>
      <DeleteAvatarConfirmationPopup
        title={t('profilePage.deleteImgTitle')}
        subTitle={t('profilePage.deleteImgSubTitle')}
        open={showDeletePopup}
        isLoading={deletingProfilePicture}
        onClose={() => setShowDeletePopup(false)}
        onDelete={handleDeleteImg}
      />
    </Dialog>
  );
};

export default UploadAvatarModal;
