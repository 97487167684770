import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { H2 } from 'src/components';
import { DashboardWrapper } from 'src/containers/wrappers';

const Tutorials = () => {
  const { t } = useTranslation();

  return (
    <DashboardWrapper breadcrumbs={[[t('guidePage.title')]]}>
      <Box component="main" sx={{ height: 'calc(100vh - 100px)', pt: 4 }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: `
                <script async src="https://js.storylane.io/js/v2/storylane.js"></script>
              `,
          }}
        />

        <H2 sx={{ my: 2 }}>{t('guidePage.editProfile')}</H2>
        <Box
          sx={{ mt: 2, mb: 4 }}
          dangerouslySetInnerHTML={{
            __html: `
              <div>
                <div class="sl-embed" style="position:relative;padding-bottom:calc(57.32% + 27px);width:100%;height:0;transform:scale(1)">
                  <iframe class="sl-demo" src="https://app.storylane.io/demo/l42fa8bnuccz" name="sl-embed" allow="fullscreen" 
                  style="position:absolute;top:0;left:0;width:100%!important;height:100%!important;border:1px solid rgba(63,95,172,0.35);
                  box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px;box-sizing:border-box;"></iframe>
                </div>
              </div>
              `,
          }}
        />

        <H2 sx={{ my: 2 }}>{t('guidePage.broadcastNav')}</H2>
        <Box
          sx={{ mt: 2, pb: 4 }}
          dangerouslySetInnerHTML={{
            __html: ` 
              <div>
                <div class="sl-embed" style="position:relative;padding-bottom:calc(57.32% + 27px);width:100%;height:0;transform:scale(1)">
                  <iframe class="sl-demo" src="https://app.storylane.io/demo/dersk3zzf70x" name="sl-embed" allow="fullscreen" 
                  style="position:absolute;top:0;left:0;width:100%!important;height:100%!important;border:1px solid rgba(63,95,172,0.35);
                  box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px;box-sizing:border-box;"></iframe>
                </div>
              </div>
              `,
          }}
        />

        <H2 sx={{ my: 2 }}>{t('guidePage.userManagement')}</H2>
        <Box
          sx={{ mt: 2, pb: 4 }}
          dangerouslySetInnerHTML={{
            __html: ` 
              <div>
                <div class="sl-embed" style="position:relative;padding-bottom:calc(57.32% + 25px);width:100%;height:0;transform:scale(1)">
                  <iframe loading="lazy" class="sl-demo" src="https://app.storylane.io/demo/79qhmdliwzxu?embed=inline" 
                  name="sl-embed" allow="fullscreen" allowfullscreen 
                  style="position:absolute;top:0;left:0;width:100%!important;height:100%!important;border:1px solid rgba(63,95,172,0.35);
                  box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px;box-sizing:border-box;"></iframe>
                </div>
              </div>
              `,
          }}
        />

        <H2 sx={{ my: 2 }}>{t('guidePage.eventCreation')}</H2>
        <Box
          sx={{ mt: 2, pb: 4 }}
          dangerouslySetInnerHTML={{
            __html: ` 
              <div>
                <div class="sl-embed" style="position:relative;padding-bottom:calc(57.32% + 25px);width:100%;height:0;transform:scale(1)">
                  <iframe loading="lazy" class="sl-demo" src="https://app.storylane.io/demo/x2duku8k5hyt?embed=inline" 
                  name="sl-embed" allow="fullscreen" allowfullscreen 
                  style="position:absolute;top:0;left:0;width:100%!important;height:100%!important;border:1px solid rgba(63,95,172,0.35);
                  box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px;box-sizing:border-box;"></iframe>
                </div>
              </div>
              `,
          }}
        />

        <H2 sx={{ my: 2 }}>{t('guidePage.addGFeditEvent')}</H2>
        <Box
          sx={{ mt: 2, pb: 4 }}
          dangerouslySetInnerHTML={{
            __html: ` 
              <div>
                <div class="sl-embed" style="position:relative;padding-bottom:calc(57.32% + 25px);width:100%;height:0;transform:scale(1)">
                  <iframe loading="lazy" class="sl-demo" src="https://app.storylane.io/demo/bduaujzvo40v?embed=inline" 
                  name="sl-embed" allow="fullscreen" allowfullscreen 
                  style="position:absolute;top:0;left:0;width:100%!important;height:100%!important;border:1px solid rgba(63,95,172,0.35);
                  box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px;box-sizing:border-box;"></iframe>
                </div>
              </div>
              `,
          }}
        />
      </Box>
    </DashboardWrapper>
  );
};

export default Tutorials;
