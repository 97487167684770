import { Box } from '@mui/material';
import { P1R, H1 } from 'src/components';
import somethingWrong from 'src/assets/somethingWrong.svg';

type Props = {
  size?: 'default' | 'small';
};

const defaultImgSize = '174px';
const smallImgSize = '64px';

const SomethingWentWrong = ({ size = 'default', ...props }: Props) => {
  const imgStyle = {
    height: size === 'small' ? smallImgSize : defaultImgSize,
    width: size === 'small' ? smallImgSize : defaultImgSize,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '80%',
        p: 4,
      }}
      {...props}
    >
      <Box sx={{ mb: 3 }}>
        <img src={somethingWrong} alt="empty state picture" style={imgStyle} />
      </Box>
      <H1 sx={{ textTransform: 'capitalize', mb: 3 }}>Something went wrong</H1>
      <P1R sx={{ textAlign: 'center', whiteSpace: 'pre-line', mb: 3 }}>
        Sorry something went wrong. <br />
        Try again later or refresh page.
      </P1R>
    </Box>
  );
};

export default SomethingWentWrong;
