import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid, MenuItem, Tooltip } from '@mui/material';
import { selectAvailableGeneralForemen } from 'src/redux/users/selectors';
import { selectIncident } from 'src/redux/incident/selectors';
import { Icon, P1R, P2, FormikInputString, FormikInputSelect, FormikInputSelectMulti } from 'src/components';
import IncidentStatus from 'src/components/IncidentsTable/IncidentStatus';
import config from 'src/config';
import { formatIncidentId } from 'src/utils';
import { ListType } from 'src/types';

import { FormValues } from '../IncidentDialog';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
  incidentStatus?: number;
};

function General({ formik, hidden = false, incidentStatus }: Props) {
  const incident = useSelector(selectIncident);
  const { t } = useTranslation();
  const availableGeneralForemen = useSelector(selectAvailableGeneralForemen);
  const [defaultForemen, setDefaultForemen] = useState<string[]>([]);
  const options: ListType[] = availableGeneralForemen.map((lineman) => ({
    key: lineman.uuid || '',
    value: `${lineman.firstName} ${lineman.lastName}`,
  }));

  /*
  const incidentRostersCreators: { owner: string; rosterStatus: number }[] = incident?.rosters?.map((roster) => ({
    owner: roster.owner,
    rosterStatus: roster.status,
  })) || [];
  */

  let statusesList: ListType[] = [];
  if (incidentStatus === 0) {
    statusesList = [
      { key: 0, value: 'Draft' },
      { key: 1, value: 'Activated' },
    ];
  } else if (incidentStatus === 1) {
    statusesList = [
      { key: 1, value: 'Activated' },
      { key: 2, value: 'Closed' },
    ];
  } else if (incidentStatus === 2) {
    statusesList = [{ key: 2, value: 'Closed' }];
  }

  useEffect(() => {
    setDefaultForemen((incident.assignedTo || []).map((option) => option.uuid));
  }, []);

  return (
    <Grid container sx={{ margin: '32px 0 16px 0', display: hidden ? 'none' : 'block' }}>
      <Grid item xs={12} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <Icon name="lightning" />
        <P2 sx={{ margin: '0 8px 0 16px', textTransform: 'uppercase' }}>{t('eventsPage.eventCode')}:</P2>
        {/* Technical debt */}
        <P1R>{formatIncidentId(incident.id)}</P1R>
        <Tooltip title={t('eventsPage.code')} placement="right" sx={{ margin: '0 8px' }} enterTouchDelay={0}>
          <Box>
            <Icon name="info" />
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="name" label={t('eventsPage.name')} subLabel={t('eventsPage.nameDescription')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="utility"
            label={t('eventsPage.utility')}
            placeholder={t('eventsPage.utility')}
            options={config.options.utilities}
            disabled={incidentStatus === 1}
            disableClearable
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="utilityContractor"
            label={t('eventsPage.utilityContractor')}
            placeholder="Contractor"
            options={config.options.utilityContractors}
            disabled={incidentStatus === 1}
            disableClearable
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="workLocation" label={t('eventsPage.workLocation')} disabled={incidentStatus === 1} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelectMulti
            formik={formik}
            field="assignedTo"
            label={t('eventsPage.assignedTo')}
            options={options}
            isChipDisabled={(option) => incidentStatus === 1 && defaultForemen.includes(option.key.toString())}
            disableClearable
          />
          {/*
          <Autocomplete
            multiple
            disableClearable
            value={options.filter((option) => formik.values.assignedTo.includes(option.value))}
            options={options.filter((option) => !formik.values.assignedTo.includes(option.value))}
            getOptionLabel={(option) => option.label}
            getOptionDisabled={(option) => (incident.status > 0 ? defaultForemen.indexOf(option.value) !== -1 : false)}
            onChange={(event: SyntheticEvent, value: readonly { value: string; label: string }[]) => {
              formik.setFieldValue('assignedTo', value.map((item) => item.value));
            }}
            renderInput={(params) => (
              <Input
                {...params}
                name="assignedTo"
                label="Assigned To"
                placeholder="Select"
                error={formik.touched.assignedTo && Boolean(formik.errors.assignedTo)}
                helperText={formik.touched.assignedTo && formik.errors.assignedTo}
                sx={{ '& .MuiInputBase-root': { height: '100%' } }}
              />
            )}
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => {
              // const isForemanPresent = incidentRostersCreators?.some((foreman) => foreman.owner === option.value);
              /* const isRosterClosed = incidentRostersCreators?.some((roster) => {
                if (option.value === roster.owner) {
                  return roster.rosterStatus === 5;
                }
              }); *

              const disabledValues = tagValue.filter(
                (foreman) => incident.assignedTo?.some(incident => incident.uuid === foreman.value)
              );

              return (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={<P3 sx={{ display: 'flex' }}>{option.label}</P3>}
                    /* disabled={incident.status > 0 ?
                      true
                      :
                      isCurrentForeman && isForemanPresent ? true : false} *
                  // disabled={incident.status === 0 ? false : isForemanPresent && !isRosterClosed}
                  disabled={incident.status === 0 ?
                    disabledValues.some((foreman) => foreman.value === option.value)
                  : incident.status > 0 ? defaultForemen.indexOf(option.value) !== -1 : false}
                  deleteIcon={<Box><Icon name="closeSmall" /></Box>}
                  sx={{
                    height: '24px',
                    backgroundColor: '#f7f7f7',
                    '& .MuiChip-deleteIcon': {
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      backgroundColor: themeColors.grayMedium,
                    },
                  }}
                />
              );
            })}
            renderOption={(props, option) => (
              <MenuItem {...props} sx={{ height: '50px' }}>
                <P1R>{option.label}</P1R>
              </MenuItem>
            )}
          />
*/}
        </Box>
      </Grid>
      {incidentStatus ? (
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%' }}>
            <FormikInputSelect
              formik={formik}
              field="status"
              label={t('eventsPage.status')}
              options={statusesList}
              disableClearable
              renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: ListType) => (
                <MenuItem {...props}>
                  <IncidentStatus status={option.key as number} />
                </MenuItem>
              )}
            />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default General;
