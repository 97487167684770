import { Box, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import { theme, themeColors } from 'src/theme';

import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import matchIcon from 'src/assets/match.svg';
import uploadPhoto from 'src/assets/uploadPhoto.svg';
import visibility from 'src/assets/visibility.svg';
import visibilityOff from 'src/assets/visibilityOff.svg';
import expand from 'src/assets/arrowDown.svg';
import expandGray from 'src/assets/arrowDownGray.svg';
import close from 'src/assets/close.svg';
import closeSmall from 'src/assets/closeSmall.svg';
import accepted from 'src/assets/offerStatus/accepted.svg';
import rejected from 'src/assets/offerStatus/rejected.svg';
import pending from 'src/assets/offerStatus/pending.svg';
import attachWhite from 'src/assets/attach.svg';
import attachYellow from 'src/assets/attachYellow.svg';
import attachBlack from 'src/assets/attachBlack.svg';
import attachRed from 'src/assets/attachRed.svg';
import attachBlue from 'src/assets/attachBlue.svg';
import lightning from 'src/assets/lightning.svg';
import filter from 'src/assets/filter.svg';
import activeFilter from 'src/assets/activeFilter.svg';
import incidentLight from 'src/assets/incidentLight.svg';
import arrowDownBlue from 'src/assets/arrowDownBlue.svg';
import foreman from 'src/assets/foreman.svg';
import out from 'src/assets/out.svg';
import expandDown from 'src/assets/expandDown.svg';
import orangeStatus from 'src/assets/orangeStatus.svg';
import duplicate from 'src/assets/duplicate.svg';
import location from 'src/assets/location.svg';
import yardFK from 'src/assets/yardFK.svg';
import time from 'src/assets/time.svg';
import hamburger from 'src/assets/hamburger.svg';
import statusRejected from 'src/assets/statusRejected.svg';
import statusReapproval from 'src/assets/statusReapproval.svg';
import statusWarning from 'src/assets/statusWarning.svg';
import redFlag from 'src/assets/redFlag.svg';
import minimizeArrow from 'src/assets/minimizeArrow.svg';
import greenFlag from 'src/assets/greenFlag.svg';
import timer from 'src/assets/timer.svg';
import timerGray from 'src/assets/timerGray.svg';
import arrowRevert from 'src/assets/arrowRevert.svg';
import arrowBlack from 'src/assets/arrowBlack.svg';
import info from 'src/assets/info.svg';
import BUCKET_TRUCK from 'src/assets/vehicles/BUCKET_TRUCK.svg';
import SQUIRT_BUCKET from 'src/assets/vehicles/SQUIRT_BUCKET.svg';
import DIGGER_DERRICK from 'src/assets/vehicles/DIGGER_DERRICK.svg';
import MECHANIC_TRUCK from 'src/assets/vehicles/MECHANIC_TRUCK.svg';
import BACKYARD_MACHINE from 'src/assets/vehicles/BACKYARD_MACHINE.svg';
import TRAILER_POLE from 'src/assets/vehicles/TRAILER_POLE.svg';
import TRAILER_SAFETY from 'src/assets/vehicles/TRAILER_SAFETY.svg';
import TRAILER_MATERIAL from 'src/assets/vehicles/TRAILER_MATERIAL.svg';
import TRAILER_CARGO from 'src/assets/vehicles/TRAILER_CARGO.svg';
import TRAILER_LOWBOY from 'src/assets/vehicles/TRAILER_LOWBOY.svg';
import TRAILER_UTILITY from 'src/assets/vehicles/TRAILER_UTILITY.svg';
import PICKUP_TRUCK from 'src/assets/vehicles/PICKUP_TRUCK.svg';
import VAN from 'src/assets/vehicles/VAN.svg';
import SUV from 'src/assets/vehicles/SUV.svg';
import checkmark from 'src/assets/checkmark.svg';
import favorite from 'src/assets/favorite.svg';
import addToFavorite from 'src/assets/addToFavorite.svg';
import popupIcon from 'src/assets/popupIcon.svg';
import split from 'src/assets/splitIcon.svg';
import splitGray from 'src/assets/splitIconGray.svg';
import restore from 'src/assets/restoreIcon.svg';
import exclamationOrange from 'src/assets/exclamationOrange.svg';
import pendingBlue from 'src/assets/pendingBlue.svg';
import arrowRight from 'src/assets/arrowRght.svg';
import clearmeIcon from 'src/assets/clearmeIcon.svg';
import clearmeIconWhite from 'src/assets/clearmeIconWhite.svg';
import broadcastIcon from 'src/assets/sidebarIcons/Broadcast.svg';
import companiesIcon from 'src/assets/sidebarIcons/Companies.svg';
import eventsIcon from 'src/assets/sidebarIcons/Events.svg';
import fleetManagmentIcon from 'src/assets/sidebarIcons/FleetManagment.svg';
import manageUsersIcon from 'src/assets/sidebarIcons/ManageUsers.svg';
import myApprovalsIcon from 'src/assets/sidebarIcons/MyApprovals.svg';
import myRostersIcon from 'src/assets/sidebarIcons/MyRosters.svg';
import notificationsIcon from 'src/assets/sidebarIcons/Notifications.svg';
import sidebarSettingsIcon from 'src/assets/sidebarIcons/Settings.svg';
import userDirectoryIcon from 'src/assets/sidebarIcons/UserDirectory.svg';
import guidesIcon from 'src/assets/sidebarIcons/Guides.svg';
import warningSignOrangeIcon from 'src/assets/warningOrange.svg';
import attentionIcon from 'src/assets/attentionIcon.svg';
import exclamationBlue from 'src/assets/exclamationBlue.svg';

const vehicles = {
  BUCKET_TRUCK,
  SQUIRT_BUCKET,
  DIGGER_DERRICK,
  MECHANIC_TRUCK,
  BACKYARD_MACHINE,
  TRAILER_POLE,
  TRAILER_SAFETY,
  TRAILER_MATERIAL,
  TRAILER_CARGO,
  TRAILER_LOWBOY,
  TRAILER_UTILITY,
  PICKUP_TRUCK,
  VAN,
  SUV,
};

type Props = {
  name:
    | 'search'
    | 'groups'
    | 'businessCenter'
    | 'settings'
    | 'notifications'
    | 'delete'
    | 'logout'
    | 'person'
    | 'add'
    | 'circularProgress'
    | 'match'
    | 'uploadPhoto'
    | 'attach'
    | 'attachRed'
    | 'attachYellow'
    | 'attachBlack'
    | 'attachBlue'
    | 'visibility'
    | 'visibilityOff'
    | 'edit'
    | 'expand'
    | 'expandGray'
    | 'close'
    | 'closeSmall'
    | 'accepted'
    | 'rejected'
    | 'pending'
    | 'lightning'
    | 'incidentLight'
    | 'arrowDownBlue'
    | 'foreman'
    | 'out'
    | 'filter'
    | 'activeFilter'
    | 'moreVert'
    | 'home'
    | 'NaN'
    | 'expandDown'
    | 'orangeStatus'
    | 'duplicate'
    | 'hamburger'
    | 'statusRejected'
    | 'statusReapproval'
    | 'statusWarning'
    | 'redFlag'
    | 'minimizeArrow'
    | 'maximizeArrow'
    | 'greenFlag'
    | 'arrowBlack'
    | 'favorite'
    | 'addToFavorite'
    | 'popupIcon'
    | 'restoreIcon'
    | 'broadcast'
    | 'companies'
    | 'events'
    | 'fleetManagment'
    | 'manageUsers'
    | 'myApprovals'
    | 'myRosters'
    | 'notifications'
    | 'sidebarSettings'
    | 'userDirectory'
    | 'guides'
    | 'warningSign'
    | 'attention'
    | string;
  color?: 'black' | 'white' | 'grayDark' | 'grayMedium' | 'grayLight' | 'error' | 'success' | 'secondary';
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
  size?: number;
  sx?: any;
  hoverColor?: string;
};

const Icon: React.FC<Props> = ({ name, color, hoverColor, fontSize, size, sx }): JSX.Element => (
  <ThemeProvider theme={createTheme(theme)}>
    <Box sx={{ display: 'flex', justifyContent: 'center', margin: '4px', ...sx }}>{iconTag(name, color, fontSize, size, hoverColor)}</Box>
  </ThemeProvider>
);

const iconTag = (
  name: string,
  color?: string,
  fontSize?: 'inherit' | 'large' | 'medium' | 'small',
  size?: number,
  hoverColor?: string,
  // eslint-disable-next-line consistent-return
) => {
  let iconColor;
  if (color === 'black') iconColor = themeColors.black;
  else if (color === 'white') iconColor = themeColors.white;
  else if (color === 'grayDark') iconColor = themeColors.grayDark;
  else if (color === 'grayMedium') iconColor = themeColors.grayMedium;
  else if (color === 'grayLight') iconColor = themeColors.grayLight;
  else if (color === 'error') iconColor = themeColors.red;
  else if (color === 'success') iconColor = themeColors.green;
  else if (color === 'secondary') iconColor = themeColors.yellowMain;

  if (name === 'search') return <SearchIcon style={{ color: iconColor }} fontSize={fontSize} />;
  if (name === 'NaN') {
    return (
      <div
        style={{
          height: '24px',
          width: '24px',
        }}
      />
    );
  }
  if (name === 'groups') return <GroupsIcon style={{ color: iconColor }} fontSize={fontSize} />;
  if (name === 'businessCenter') {
    return <BusinessCenterIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'settings') return <SettingsIcon style={{ color: iconColor }} fontSize={fontSize} />;
  if (name === 'notifications') {
    return <NotificationsIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'delete') {
    return <DeleteOutlineIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'moreVert') {
    return <MoreVertIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'logout') {
    return <LogoutIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'person') {
    return <PersonIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'home') {
    return <HomeIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'expand') {
    return <img src={expand} alt="expand" />;
  }
  if (name === 'expandGray') {
    return <img src={expandGray} alt="expand" />;
  }
  if (name === 'add') {
    return <AddIcon fontSize={fontSize} sx={{ color: iconColor, ':hover': { color: hoverColor }, fontSize: `${size}px` }} />;
  }
  if (name === 'circularProgress') {
    return (
      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
        <CircularProgress color="inherit" size={size || 40} />
      </Stack>
    );
  }
  if (name === 'attach') {
    return <img src={attachWhite} alt="attach" />;
  }
  if (name === 'attachYellow') {
    return <img src={attachYellow} alt="attach" />;
  }
  if (name === 'attachBlack') {
    return <img src={attachBlack} alt="attach" />;
  }
  if (name === 'attachRed') {
    return <img src={attachRed} alt="attach" />;
  }
  if (name === 'attachBlue') {
    return <img src={attachBlue} alt="attach" />;
  }
  if (name === 'hamburger') {
    return <img src={hamburger} alt="hamburger menu" />;
  }
  if (name === 'match') {
    return <img src={matchIcon} alt="Check mark" />;
  }
  if (name === 'uploadPhoto') {
    return <img src={uploadPhoto} alt="upload" />;
  }
  if (name === 'arrowBlack') {
    return <img src={arrowBlack} alt="arrow" />;
  }
  if (name === 'foreman') {
    return <img src={foreman} alt="foreman" />;
  }
  if (name === 'out') {
    return <img src={out} alt="out" />;
  }
  if (name === 'incidentLight') {
    return <img src={incidentLight} alt="incident" />;
  }
  if (name === 'visibility') {
    return <img src={visibility} alt="visibility" />;
  }
  if (name === 'minimizeArrow') {
    return <img src={minimizeArrow} alt="minimize arrow" />;
  }
  if (name === 'maximizeArrow') {
    return <img style={{ transform: 'rotate(180deg)' }} src={minimizeArrow} alt="maximize arrow" />;
  }
  if (name === 'arrowDownBlue') {
    return <img src={arrowDownBlue} alt="more" />;
  }
  if (name === 'visibilityOff') {
    return <img src={visibilityOff} alt="visibilityOff" />;
  }
  if (name === 'close') {
    return <img src={close} alt="close" />;
  }
  if (name === 'expandDown') {
    return <img src={expandDown} alt="expand" />;
  }
  if (name === 'closeSmall') {
    return <img src={closeSmall} alt="close" />;
  }
  if (name === 'accepted') {
    return <img src={accepted} alt="accepted" />;
  }
  if (name === 'rejected') {
    return <img src={rejected} alt="rejected" />;
  }
  if (name === 'redFlag') {
    return <img src={redFlag} alt="redFlag" />;
  }
  if (name === 'greenFlag') {
    return <img src={greenFlag} alt="greenFlag" />;
  }
  if (name === 'pending') {
    return <img src={pending} alt="pending" />;
  }
  if (name === 'lightning') {
    return <img src={lightning} alt="lightning" />;
  }
  if (name === 'filter') {
    return <img src={filter} alt="filter" />;
  }
  if (name === 'activeFilter') {
    return <img src={activeFilter} alt="filter" />;
  }
  if (name === 'duplicate') {
    return <img src={duplicate} alt="duplicate" />;
  }
  if (name === 'orangeStatus') {
    return <img src={orangeStatus} alt="status" />;
  }
  if (name === 'location') {
    return <img src={location} alt="location" />;
  }
  if (name === 'yardFK') {
    return <img src={yardFK} alt="yardFK" />;
  }
  if (name === 'time') {
    return <img src={time} alt="time" />;
  }
  if (name === 'edit') {
    return <EditOutlinedIcon style={{ color: iconColor, fontSize: `${size}px` }} fontSize={fontSize} />;
  }
  if (name === 'arrowBack') {
    return <ArrowBackIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'statusRejected') {
    return <img src={statusRejected} alt="statusRejected" />;
  }
  if (name === 'statusReapproval') {
    return <img src={statusReapproval} alt="statusReapproval" />;
  }
  if (name === 'statusWarning') {
    return <img src={statusWarning} alt="statusWarning" />;
  }
  if (name === 'timer') {
    return <img src={timer} alt="timer" />;
  }
  if (name === 'timerGray') {
    return <img src={timerGray} alt="timer" />;
  }
  if (name === 'info') {
    return <img src={info} alt="info" />;
  }
  if (name === 'save') {
    return <SaveIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'cancel') {
    return <CancelOutlinedIcon style={{ color: iconColor }} fontSize={fontSize} />;
  }
  if (name === 'arrowRevert') {
    return <img src={arrowRevert} alt="arrowRevert" />;
  }
  if (name === 'checkmark') {
    return <img src={checkmark} alt="checkmark" />;
  }
  if (name === 'favorite') {
    return <img src={favorite} alt="favorite" />;
  }
  if (name === 'addToFavorite') {
    return <img src={addToFavorite} alt="favorite" />;
  }
  if (name === 'popupIcon') {
    return <img src={popupIcon} alt="open" />;
  }
  if (name === 'downloadCSV') {
    return <FileDownloadOutlinedIcon fontSize={fontSize} />;
  }
  if (name === 'split') {
    return <img src={split} alt="split" />;
  }
  if (name === 'restoreIcon') {
    return <img src={restore} alt="restore" />;
  }
  if (name === 'splitGray') {
    return <img src={splitGray} alt="split" />;
  }
  if (name === 'exclamationOrange') {
    return <img src={exclamationOrange} alt="exclamationOrange" />;
  }
  if (name === 'pendingBlue') {
    return <img src={pendingBlue} alt="pendingBlue" />;
  }
  if (name === 'arrowRight') {
    return <img src={arrowRight} alt="arrowRight" />;
  }
  if (name === 'clearmeIcon') {
    return <img src={clearmeIcon} alt="clearmeIcon" />;
  }
  if (name === 'clearmeIconWhite') {
    return <img src={clearmeIconWhite} alt="clearmeIconWhite" />;
  }
  if (name === 'broadcast') {
    return <img src={broadcastIcon} alt="broadcastIcon" />;
  }
  if (name === 'companies') {
    return <img src={companiesIcon} alt="companiesIcon" />;
  }
  if (name === 'events') {
    return <img src={eventsIcon} alt="eventsIcon" />;
  }
  if (name === 'fleetManagment') {
    return <img src={fleetManagmentIcon} alt="fleetManagmentIcon" />;
  }
  if (name === 'manageUsers') {
    return <img src={manageUsersIcon} alt="manageUsersIcon" />;
  }
  if (name === 'myApprovals') {
    return <img src={myApprovalsIcon} alt="myApprovalsIcon" />;
  }
  if (name === 'myRosters') {
    return <img src={myRostersIcon} alt="myRostersIcon" />;
  }
  if (name === 'notifications') {
    return <img src={notificationsIcon} alt="notificationsIcon" />;
  }
  if (name === 'sidebarSettings') {
    return <img src={sidebarSettingsIcon} alt="settingsIcon" />;
  }
  if (name === 'userDirectory') {
    return <img src={userDirectoryIcon} alt="userDirectoryIcon" />;
  }
  if (name === 'guides') {
    return <img src={guidesIcon} alt="guidesIcon" />;
  }
  if (name === 'warningSign') {
    return <img src={warningSignOrangeIcon} alt="warningSign" />;
  }
  if (name === 'attention') {
    return <img src={attentionIcon} alt="attentionIcon" />;
  }
  if (name === 'exclamationBlue') {
    return <img src={exclamationBlue} alt="exclamationBlue" />;
  }

  if (Object.keys(vehicles).includes(name)) {
    // @ts-ignore
    return <img src={vehicles[name]} style={{ width: '40px' }} alt="vehicle" />;
  }
};

export default Icon;
