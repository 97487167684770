import React from 'react';
import { FormikProps } from 'formik';
import { TextFieldProps } from '@mui/material';
import Input from '../Input';

type FormikInputStringProps = {
  formik: FormikProps<any>;
  field: string;
  label: string;
  subLabel?: string;
  disabled?: boolean;
  isOptional?: boolean;
  autoFocus?: boolean;
  errorHelper?: Record<string, string[]>;
} & TextFieldProps;

const FormikInputString: React.FC<FormikInputStringProps> = ({ formik, field, errorHelper, ...props }): JSX.Element => (
  <Input
    name={field}
    type="text"
    value={formik.values[field]}
    onChange={formik.handleChange}
    // @ts-ignore
    error={(formik.touched[field] && Boolean(formik.errors[field])) || (errorHelper && !!errorHelper[field])}
    // @ts-ignore
    helperText={(formik.touched[field] && formik.errors[field]) || (errorHelper && errorHelper[field])}
    {...props}
  />
);

export default FormikInputString;
