import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import { PATHS } from 'src/navigation';
import { setParams } from 'src/utils';
import { Toolbar, RostersTable, H1, P1R, Loader, SomethingWentWrongState } from 'src/components';
import { loadRosters } from 'src/redux/rosters/actions';
import { selectMyRosters, selectRostersLoading, selectRostersError } from 'src/redux/rosters/selectors';

import { useTranslation } from 'react-i18next';
import linemanGroup from 'src/assets/linemanGroup.svg';
import { DashboardWrapper } from '../wrappers';

export default function Rosters() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRosters = useSelector(selectMyRosters);
  const isMyRostersLoading = useSelector(selectRostersLoading);
  const myRostersLoadingError = useSelector(selectRostersError);

  useEffect(() => {
    dispatch(loadRosters.init());
  }, [dispatch]);

  if (myRostersLoadingError) {
    return (
      <DashboardWrapper breadcrumbs={[['My Rosters']]}>
        <SomethingWentWrongState />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper breadcrumbs={[['My Rosters']]} navigationChildren={myRosters?.length ? <Toolbar options="mini" /> : null}>
      <Box
        component="main"
        sx={{
          height: 'calc(100vh - 100px)',
          pt: 4,
        }}
      >
        {isMyRostersLoading ? (
          <Loader />
        ) : myRosters?.length ? (
          <RostersTable rosters={myRosters} setRoster={(rid: number): void => navigate(setParams(PATHS.ROSTER_PAGE, { id: rid.toString() }))} />
        ) : (
          <Box
            sx={{
              mt: 14,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ ml: 1 }}>
              <img src={linemanGroup} alt="Empty list" />
            </Box>
            <H1 sx={{ mb: 3 }}>{t('rosterPage.noRostersCreated')}</H1>
            <P1R>
              {t('rosterPage.goToEvents')}
              <br />
              {t('rosterPage.correspondingButton')}
            </P1R>
          </Box>
        )}
      </Box>
    </DashboardWrapper>
  );
}
