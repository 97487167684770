import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams, GridColDef, GridCellParams } from '@mui/x-data-grid';

import { ListType, UserType, PermissionEnum, Role } from 'src/types';
import { RoleType } from 'src/shared/enums/roleType.enum';
import { Table, InputSelectMulti, Icon, VerificationStatus, UserStatus } from 'src/components';
import config from 'src/config';

import { selectUsersFavoriteLoading } from 'src/redux/users/selectors';
import { favoriteUser } from 'src/redux/user/actions';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { TableProps } from '../Table';
import ClearStatus from '../ClearStatus';

export const usersColumnVisibilityModel = {
  firstName: false,
  lastName: false,
  registrationDate: false,
  clearStatus: false,
  favorite: false,
  email: false,
  phone: false,
  street: false,
  city: false,
  stateCode: false,
  zipcode: false,
  userRoles: false,
  status: false,
  gender: false,
  verificationStatus: false,
};

type Props = {
  users: UserType[];
  isEditable?: boolean;
  isFavoritesEditable?: boolean;
  hiddenColumns?: { [key: string]: boolean };
} & Partial<TableProps>;

const UsersTable: React.FC<Props> = ({ users, isEditable = false, isFavoritesEditable = true, hiddenColumns = {}, ...props }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [columnsVisible, setColumnsVisible] = useState<{ [key: string]: boolean }>({});
  const viewHidden = config.can(PermissionEnum.VIEW_HIDDEN_ROLES);
  const configRoles = config.options.roles
    .filter((role: Role) => (role.types.includes(RoleType.HIDDEN) ? viewHidden : true))
    .map((role: Role) => ({ key: role.slug, value: role.name })) as ListType[];
  const [loadingState, setLoadingState] = useState({});
  const isUserFavoriteLoading = useSelector(selectUsersFavoriteLoading);

  const handleFavorite = (id: string, userUuid: string, favorite: boolean) => {
    setLoadingState((prevState) => ({ ...prevState, [id]: true }));
    dispatch(favoriteUser.init({ favoriteUuid: userUuid, action: favorite ? 'REMOVE' : 'ADD' }));

    if (isUserFavoriteLoading) {
      setLoadingState((prevState) => ({ ...prevState, [id]: true }));
    }
  };

  useEffect(() => {
    if (!isUserFavoriteLoading) {
      setLoadingState({});
    }
  }, [isUserFavoriteLoading]);

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('users.firstName'),
      editable: isEditable,
      minWidth: 125,
    },
    {
      field: 'lastName',
      headerName: t('users.lastName'),
      editable: isEditable,
      minWidth: 175,
    },
    {
      field: 'email',
      headerName: t('users.email'),
      minWidth: 280,
    },
    {
      field: 'registrationDate',
      headerName: t('users.registrationDate'),
      editable: isEditable,
      minWidth: 175,
      renderCell: (params: GridRenderCellParams) => dayjs(params.row.createdAt).format('MMM D, YYYY'),
    },
    {
      field: 'clearStatus',
      headerName: t('users.clearStatus'),
      editable: isEditable,
      minWidth: 175,
      renderCell: (params: GridRenderCellParams<any, number>): React.ReactNode => <ClearStatus clearStatus={params.row.clearStatus} />,
    },
    {
      field: 'favorite',
      headerName: t('users.addToFavorite'),
      renderCell: (params: GridCellParams): React.ReactNode => {
        const isLoading = (loadingState as { [key: string]: boolean })[params.id] || false;

        return (
          <Box sx={{ pl: 1 }}>
            {isFavoritesEditable ? (
              <Box sx={{ cursor: 'pointer' }} onClick={() => handleFavorite(params.id.toString(), params.row.uuid, params.row.favorite)}>
                {isLoading ? <Icon size={19} name="circularProgress" /> : <Icon name={!params.row.favorite ? 'addToFavorite' : 'favorite'} />}
              </Box>
            ) : (
              <Box>
                <Icon name={params.row.favorite && 'favorite'} />
              </Box>
            )}
          </Box>
        );
      },
      align: 'center',
      headerAlign: 'center',
      minWidth: 175,
    },
    {
      field: 'preferredName',
      headerName: t('users.preferredName'),
      editable: isEditable,
      minWidth: 175,
    },
    {
      field: 'phone',
      headerName: t('users.phone'),
      minWidth: 150,
    },
    {
      field: 'city',
      headerName: t('users.city'),
      editable: isEditable,
      minWidth: 150,
    },
    {
      field: 'state',
      headerName: t('users.state'),
      editable: isEditable,
      minWidth: 150,
    },
    {
      field: 'localUnion',
      headerName: t('users.localUnion'),
      editable: isEditable,
      minWidth: 150,
    },
    {
      field: 'userRoles',
      headerName: t('users.roles'),
      editable: isEditable,
      flex: 1,
      minWidth: 300,

      renderCell: (params: GridRenderCellParams<any, ListType[]>): React.ReactNode => (
        // TODO: Is Object.keys() considered DataGrid safe or do we need to use a memo?
        <InputSelectMulti
          name="userRoles"
          options={configRoles}
          getValue={() => (params.row.userRoles ? Object.keys(params.row.userRoles) : params.row.roles) || []}
          isChipDisabled={() => true}
          disableClearable
          readOnly
          sx={{ ml: -2, mb: -3 }}
        />
      ),
      renderEditCell: (params: GridRenderCellParams<any, ListType[]>): React.ReactNode => (
        // TODO: Is Object.keys() considered DataGrid safe or do we need to use a memo?
        <InputSelectMulti
          name="userRoles"
          // eslint-disable-next-line camelcase
          options={configRoles}
          getValue={() => (params.row.userRoles ? Object.keys(params.row.userRoles) : params.row.roles) || []}
          disableClearable
        />
      ),
    },
    {
      field: 'verificationStatus',
      headerName: t('users.verificationStatus'),
      minWidth: 180,
      renderCell: (params: GridRenderCellParams<any, number>): React.ReactNode => (
        <VerificationStatus verificationStatus={params.row.unionInfoStatus} />
      ),
    },
    {
      field: 'status',
      headerName: t('users.status'),
      minWidth: 130,
      renderCell: (params: GridRenderCellParams<any, number>): React.ReactNode => <UserStatus status={params.row.status} />,
    },
  ];

  const getRowHeight = React.useCallback(() => (columnsVisible?.userRoles ? 'auto' : null), [columnsVisible?.userRoles]);
  const columnsToDisplay = columns.filter((col) => !hiddenColumns[col.field]);

  return (
    <Table
      {...props}
      type="advanced"
      columns={columnsToDisplay}
      rows={users}
      getRowId={(row) => row.uuid}
      disableRowSelectionOnClick
      onStateChange={({ columns }) => {
        setColumnsVisible(columns.columnVisibilityModel);
      }}
      getRowHeight={getRowHeight}
    />
  );
};

export default UsersTable;
