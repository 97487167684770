import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Menu, MenuItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { PATHS } from 'src/navigation';
import { UserType, Role, PermissionEnum } from 'src/types';
import { P1R, P1M, P3, Avatar } from 'src/components';
import config from 'src/config';
import { themeColors } from 'src/theme';
import Icon from 'src/components/Icon';

type MenuItemType = {
  name: string;
  icon: JSX.Element;
  path?: string;
  disabled?: boolean;
  permission?: string[];
  children?: MenuItemType[];
};

type SubMenuProps = {
  item: MenuItemType;
  subIndex: number;
  open: boolean;
  isChildrenItem?: boolean;
  expanded?: boolean;
  onToggle?: () => void;
};

const SubMenu: React.FC<SubMenuProps> = ({ item, subIndex, open, isChildrenItem, expanded, onToggle }): JSX.Element => {
  const navigate = useNavigate();
  // Technical Debt: refactoring it  <ApprovalRoundedIcon sx={iconStyle} />
  const isActive = (path?: string) => path && window.location.pathname.indexOf(path?.toString() || '') === 0;
  const isChildrenActive = (children?: MenuItemType[]) => children?.some((item) => isActive(item.path));

  return (
    <Fragment key={subIndex}>
      <ListItemButton
        key={subIndex}
        disabled={(!item.path && !item.children) || item.disabled}
        onClick={() => {
          if (item.children) {
            onToggle?.();
            const childWithPath = item.children.find((child) => child.path);
            if (!isChildrenActive(item.children)) {
              navigate(childWithPath?.path || item.children[0].path || '');
            }
          }

          item.path && navigate(item.path);
        }}
        sx={{
          margin: open ? '0 10px' : '0 auto',
          borderRadius: '5px',
          ...((isActive(item.path) && !isChildrenItem) || isChildrenActive(item.children)
            ? {
                backgroundColor: 'black!important',
                color: 'white!important',
              }
            : {}),
          minHeight: '56px',
          minWidth: '56px',
          width: open ? 'auto' : '56px',
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: '24px',
            mr: '10px',
            color: isActive(item.path) || isChildrenActive(item.children) ? themeColors.white : 'black',
            fontSize: '25px',
          }}
        >
          {open && isChildrenItem && isActive(item.path) ? (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '6px', height: '6px', backgroundColor: `${themeColors.black}`, borderRadius: '50%' }} />
            </Box>
          ) : (
            <Box sx={{ filter: isActive(item.path) || isChildrenActive(item.children) ? 'invert(100%)' : 'none' }}>{item.icon}</Box>
          )}
        </ListItemIcon>

        {open && <ListItemText primary={isChildrenItem && isActive(item.path) ? <P1M>{item.name}</P1M> : <P1R>{item.name}</P1R>} />}

        {item.children && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {expanded ? (
              <Box sx={{ transform: 'rotate(180deg)' }}>
                <Icon name="expand" sx={{ p: 0, m: 0 }} />
              </Box>
            ) : (
              (isChildrenActive(item.children) && <Icon name="expand" sx={{ p: 0, m: 0 }} />) || (
                <Box sx={{ width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box>
                    <Icon name="arrowBlack" sx={{ p: 0, m: 0 }} />
                  </Box>
                </Box>
              )
            )}
          </Box>
        )}
      </ListItemButton>

      {expanded ||
        (isChildrenActive(item.children) &&
          item.children?.map((item, index) => (
            <Box>
              <SubMenu item={item} key={index} subIndex={index} open={open} isChildrenItem />
            </Box>
          )))}
    </Fragment>
  );
};

export function MainListItems(open: boolean, user: UserType) {
  const { t } = useTranslation();
  const [expandedItems, setExpandedItems] = useState<{ [key: number]: boolean }>({});
  const isDeactivated = !user.status;

  const handleToggle = (index: number) => {
    setExpandedItems((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const menu: MenuItemType[] = [
    {
      name: t('sidebarItems.events'),
      icon: <Icon name="events" />,
      path: PATHS.INCIDENTS_PAGE,
      permission: [PermissionEnum.VIEW_LIST_EVENTS],
      disabled: isDeactivated,
    },
    {
      name: t('sidebarItems.myRosters'),
      icon: <Icon name="myRosters" />,
      path: PATHS.ROSTERS_PAGE,
      permission: [PermissionEnum.ADD_NEW_ROSTER_AS_ASSIGNED_GF, PermissionEnum.ADD_NEW_ROSTER_IN_ANY_EVENT],
      disabled: isDeactivated,
    },
    {
      name: t('sidebarItems.broadcast'),
      icon: <Icon name="broadcast" />,
      path: PATHS.BROADCASTS_PAGE,
      permission: [PermissionEnum.VIEW_LIST_INVITATIONS],
      disabled: isDeactivated,
    },
    {
      name: t('sidebarItems.fleetManagement'),
      icon: <Icon name="fleetManagment" />,
      permission: [PermissionEnum.VIEW_LIST_VEHICLES],
      disabled: isDeactivated,
      children: [
        {
          name: t('sidebarItems.dashboard'),
          icon: <span className="icon-fleet-dashboard" />,
          path: PATHS.FLEET_PAGE_DASHBOARD,
          permission: [PermissionEnum.VIEW_LIST_VEHICLES],
        },
        {
          name: t('sidebarItems.reports'),
          icon: <span className="icon-fleet-reports" />,
          path: PATHS.FLEET_PAGE_REPORTS,
          permission: [PermissionEnum.VIEW_LIST_VEHICLES],
        },
        {
          name: t('sidebarItems.inventory'),
          icon: <span className="icon-fleet-inventory" />,
          path: PATHS.FLEET_PAGE_INVENTORY,
          permission: [PermissionEnum.VIEW_LIST_VEHICLES],
        },
      ].filter((item) => (item.permission ? item.permission.some((permission) => config.can(permission)) : true)),
    },
    {
      name: t('sidebarItems.users'),
      icon: <Icon name="manageUsers" />,
      permission: [PermissionEnum.VIEW_BLOCKED_USERS_LIST],
      disabled: isDeactivated,
      children: [
        {
          name: t('sidebarItems.myManagedUsers'),
          icon: <span className="icon-fleet-reports" />,
          path: PATHS.MY_USERS_PAGE,
        },
        {
          name: t('sidebarItems.userDirectory'),
          icon: <span className="icon-fleet-reports" />,
          path: PATHS.USERS_PAGE,
        },
      ],
    },
    // {
    //   name: t('sidebarItems.notifications'),
    //   icon: <span className="icon-notification" />,
    //   disabled: isDeactivated,
    // },
    {
      name: t('sidebarItems.userDirectory'),
      icon: <Icon name="userDirectory" />,
      path: PATHS.USERS_PAGE,
      permission: [PermissionEnum.VIEW_ACTIVE_USERS_LIST],
      disabled: isDeactivated,
    },
    {
      name: t('sidebarItems.settings'),
      icon: <Icon name="sidebarSettings" />,
      disabled: isDeactivated,
      // path: PATHS.SETTINGS_PAGE,
      // permission: ['view_settings_permissions', 'edit_settings_permissions'],
      children: [
        {
          name: t('sidebarItems.myAccount'),
          icon: <span className="icon-fleet-dashboard" />,
          path: PATHS.SETTINGS_MY_ACCOUNT,
          // permission: ['view_list_vehicles'],
        },
        {
          name: t('sidebarItems.tables'),
          icon: <span className="icon-fleet-reports" />,
          path: PATHS.SETTINGS_TABLES,
          // permission: ['view_list_vehicles'],
        },
        {
          name: t('sidebarItems.rbacManagement'),
          icon: <span className="icon-fleet-inventory" />,
          path: PATHS.SETTINGS_PAGE_RBAC,
          permission: [PermissionEnum.VIEW_ROLE_PERMISSIONS],
        },
      ].filter((item) => (item.permission ? item.permission.some((permission) => config.can(permission)) : true)),
    },
    {
      name: t('sidebarItems.guide'),
      icon: <Icon name="guides" />,
      path: PATHS.TUTORIALS,
    },
  ]
    .filter((item) => (item.permission ? item.permission.some((permission) => config.can(permission)) : true))
    .filter((item, _, array) => {
      const hasUsersItem = array.some((menuItem) => menuItem.name === 'Users');

      if (hasUsersItem) {
        return item.name !== 'User Directory';
      }

      return true;
    });

  return (
    <Box sx={{ flex: 1, backgroundColor: themeColors.grayLight }}>
      {menu.map((item: MenuItemType, index: number) => (
        <SubMenu item={item} key={index} subIndex={index} open={open} expanded={!!expandedItems[index]} onToggle={() => handleToggle(index)} />
      ))}
    </Box>
  );
}

export function SecondaryListItems(user: UserType, logout: () => void, changeRole: (role: string) => void, open: boolean) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const open2 = Boolean(anchorEl);
  const { userRoles, firstName, lastName, currentRole, status, attachments } = user;
  const currentRoleName = (config.options.roles as Role[]).find((role) => role.slug === currentRole)?.name || currentRole;
  const displayName = `${firstName ?? 'New'} ${lastName ?? 'User'}`;
  const isDeactivated = !status;

  const openProfile = () => {
    navigate(PATHS.MY_PROFILE_PAGE);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    logout();
  };

  return (
    <Box sx={{ flex: 0, backgroundColor: themeColors.grayLight }}>
      <Divider sx={{ my: 1 }} />
      <ListItemButton
        id="demo-positioned-button-dots"
        aria-controls={open2 ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? 'true' : undefined}
        sx={{ minHeight: '70px' }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            margin: open ? '0 16px 0 0' : '0 auto',
            minWidth: '44px',
          }}
        >
          <Avatar name={displayName} src={attachments?.profile_picture?.location || ''} sx={{ borderRadius: '4px', width: '44px', height: '44px' }} />
        </ListItemIcon>
        {open && (
          <ListItemText
            primary={
              <>
                <P1R>{displayName}</P1R>
                {open && (
                  <MoreHorizIcon
                    sx={{
                      position: 'absolute',
                      display: 'block',
                      right: '8px',
                      top: '10px',
                    }}
                    id="demo-positioned-button-dots"
                  />
                )}
              </>
            }
            secondary={<P3>{currentRoleName}</P3>}
          />
        )}
      </ListItemButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button-dots"
        anchorEl={anchorEl}
        open={open2}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          ml: { lg: open ? '230px' : '60px' },
          '& .MuiMenu-paper': {
            boxShadow: 'none',
            backgroundColor: themeColors.grayLight,
          },
        }}
      >
        <P1M sx={{ m: '16px 0 24px 15px' }}>Switch Roles</P1M>
        <MenuItem
          disabled
          sx={{
            margin: '16px 16px 8px 16px',
            height: '72px',
            padding: '32px 0 26px 24px',
            color: 'white',
            bgcolor: 'black',
            borderRadius: '4px',
            opacity: '1!important',
          }}
        >
          <P1R sx={{ minWidth: '184px' }}>{currentRoleName}</P1R>
          <Box sx={{ m: '0 20px' }}>
            <Icon name="match" fontSize="small" />
          </Box>
        </MenuItem>
        {userRoles &&
          Object.keys(userRoles)
            .filter((role: string) => role !== currentRole)
            .map((slug: string) => (
              <MenuItem key={slug} sx={{ height: '72px', pl: '44px' }} onClick={() => changeRole(slug)} disabled={isDeactivated}>
                <P1R>{(config.options.roles as Role[]).find((role) => role.slug === slug)?.name || slug}</P1R>
              </MenuItem>
            ))}
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={openProfile} sx={{ height: '72px', pl: '32px' }}>
          <Icon name="person" fontSize="small" />
          <P1R sx={{ pl: '8px' }}>{t('switchRoles.myProfile')}</P1R>
        </MenuItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleLogOut} sx={{ height: '72px', pl: '32px' }}>
          <Icon name="logout" fontSize="small" />
          <P1R sx={{ pl: '8px' }}>{t('switchRoles.logout')}</P1R>
        </MenuItem>
      </Menu>
    </Box>
  );
}
