import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import fleetImg from 'src/assets/fleet.svg';
import { loadVehicles } from 'src/redux/vehicles/actions';
import { selectVehicles, selectVehiclesError, selectVehiclesLoading } from 'src/redux/vehicles/selectors';
import { H1, H2, Loader, P1R, SomethingWentWrongState } from 'src/components';

import { useTranslation } from 'react-i18next';
import { DashboardWrapper } from '../wrappers';
import FleetObjectivesTable from './FleetObjectivesTable';
import FleetReadinessTable from './FleetReadinessTable';
import FleetAdminTable from './FleetAdminTable';

export default function FleetDashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fleet = useSelector(selectVehicles);
  const isFleetLoading = useSelector(selectVehiclesLoading);
  const isFleetLoadingError = useSelector(selectVehiclesError);

  useEffect(() => {
    dispatch(loadVehicles.init());
  }, []);

  if (isFleetLoadingError) {
    return (
      <DashboardWrapper breadcrumbs={[['Fleet Management Dashboard']]}>
        <SomethingWentWrongState />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper breadcrumbs={[['Fleet Management Dashboard']]}>
      {isFleetLoading ? (
        <Loader />
      ) : fleet && fleet.length ? (
        <>
          <Box component="main" sx={{ pt: 4 }}>
            <div>
              <H2 sx={{ mb: 3 }}>{t('fleetDashboardPage.objectivesDashboard')}</H2>
            </div>
            <div>
              <FleetObjectivesTable fleet={fleet} />
            </div>
          </Box>
          <Box component="main" sx={{ pt: 4 }}>
            <div>
              <H2 sx={{ mb: 3 }}>{t('fleetDashboardPage.readinessDashboard')}</H2>
            </div>
            <div>
              <FleetReadinessTable fleet={fleet} />
            </div>
          </Box>
          <Box component="main" sx={{ pt: 4 }}>
            <div>
              <H2 sx={{ mb: 3 }}>{t('fleetDashboardPage.adminDashboard')}</H2>
            </div>
            <div>
              <FleetAdminTable fleet={fleet} />
            </div>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            mt: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ ml: 1 }}>
            <img src={fleetImg} alt="Empty list" />
          </Box>
          <H1 sx={{ mb: 3 }}>{t('fleetDashboardPage.noReports')}</H1>
          <P1R>{t('fleetDashboardPage.noReportsCreated')}</P1R>
        </Box>
      )}
    </DashboardWrapper>
  );
}
